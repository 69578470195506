import Circle from 'components/markup/loading/Circle'
import throttle from 'utils/throttle';

const TableStandardSearch = ({ placeholder, onSearchChange, loading}) => {

    return (
        <div className="dataTables_filter pl-2 pr-4 pb pt-3 pb-2 position-relative">
            <div className="row">
                <div className="align-self-center col-md-6">
                    <label>
                        <input 
                            onChange={throttle(onSearchChange, 1000, 6)}
                            placeholder={placeholder ? placeholder : 'Search...'}
                            // value={searchString} 
                            type="text" 
                            className="form-control form-control" 
                            style={{width: 190}} 
                        />
                         {loading && (
                            <span className="d-inline-block">
                                <Circle style={{display: 'inline-block', top: -4, position: 'absolute' }} />
                            </span>
                        )}
                    </label>
                </div>
                <div className="text-right align-self-center col-md-6">

                </div>
            </div>
        </div>
    )

}

export default TableStandardSearch