/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    selected_division: {},
    on_call: {},
    email_editors: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_STATE:

            return {
                ...state,
                ...action.payload
            }
        
        case actionTypes.ADD_EMAIL_EDITOR:
            const addEmailState = JSON.parse(JSON.stringify(state));
            addEmailState.email_editors.push(action.payload);

            return addEmailState
        
        case actionTypes.REMOVE_EMAIL_EDITOR:
            let removeEmailState = JSON.parse(JSON.stringify(state));
            // console.log(removeEmailState)
            // console.log(action.payload)
            removeEmailState.email_editors = removeEmailState.email_editors.filter(e => e.uuid !== action.payload)
            // console.log(removeEmailState)

            return removeEmailState

        default:
            return state;

    }

}

export default reducer;
