import * as actionTypes from '../../actions';
import store from 'store';

export const setObjects = (collection, obj) => {
    store.dispatch({
        type: actionTypes.SET_OBJECTS,
        payload: { collection, obj },
    });
}

export const setMultipleObjects = (objects) => {
    store.dispatch({
        type: actionTypes.SET_MULTIPLE_OBJECTS,
        payload: objects,
    });
}
