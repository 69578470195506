import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";
import { formatCurrency } from 'utils/currency';

const DashboardOverview = ({ data }) => (
    <Card>
        <CardHeader>
            <CardTitle className='mb-0 text-ceter'>Billing</CardTitle>
        </CardHeader>

        <CardBody className='text-center p-0' style={{overflow: 'hidden'}}>

            <Row className='mb-0'>

                <Col className='border-right py-3'>
                    <h3>Successfully Charged</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.succeeded_amount)}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Total Payments</h3>
                    <p className='mb-0 display-4'>{data.payments.total}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Added Payment Plans</h3>
                    <p className='mb-0 display-4'>{data.paymentSubscriptions.total}</p>
                </Col>
            
                <Col className='border-right py-3'>
                    <h3>Added Plan Total</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.paymentSubscriptions.total_balance)}</p>
                </Col>

            </Row>
        
            <Row className='mb-0 border-top'>

                <Col className='border-right py-3'>
                    <h3>Percent Succeeded</h3>
                    <p className='mb-0 display-4'>
                        {data.payments.total ? ((data.payments.succeeded / data.payments.total) * 100).toFixed(2) + '%' : '-'}
                    </p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Amount Succeeded</h3>
                    <p className='mb-0 display-4'>
                        {data.payments.total_amount ? ((data.payments.succeeded_amount / data.payments.total_amount) * 100).toFixed(2) + '%' : '-'}
                    </p>
                </Col>

            </Row>
        
            <Row className='border-top mb-0'>

                <Col className='border-right py-3'>
                    <h3>Partial Payments</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.partial_amount)}</p>
                    <p className='text-sm mb-0'>{data.payments.partial} Total</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Refunded Payments</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.refunded_amount)}</p>
                    <p className='text-sm mb-0'>{data.payments.refunded} Total</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Voided Payments</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.voided_amount)}</p>
                    <p className='text-sm mb-0'>{data.payments.voided} Total</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Failed Payments</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.failed_amount)}</p>
                    <p className='text-sm mb-0'>{data.payments.failed} Total</p>
                </Col>

                <Col className='border-right py-3'>
                    <h3>Charge Backs</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.chargeback_amount)}</p>
                    <p className='text-sm mb-0'>{data.payments.chargeback} Total</p>
                </Col>
            
            </Row>
        
            <Row className='border-top mb-0'>

                <Col className='border-right py-3'>
                    <h3>One Time Payments</h3>
                    <p className='mb-0 display-4 '>
                        <div>{data.payments.one_time}</div>
                        <small className='mt--2 d-block'><small>{data.payments.one_time ? ((data.payments.one_time / data.payments.total) * 100).toFixed(2): '0'}%</small></small>

                    </p>
                </Col>

                <Col className='border-right py-3'>
                    <h3>Recurring Payments</h3>
                    <p className='mb-0 display-4 '>
                        <div>{data.payments.recurring}</div>
                        <small className='mt--2 d-block'><small>{data.payments.recurring ? ((data.payments.recurring / data.payments.total) * 100).toFixed(2): '0'}%</small></small>
                    </p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Cash Payments</h3>
                    <p className='mb-0 display-4 '>
                        <div>{data.payments.cash}</div>
                        <small className='mt--2 d-block'><small>{data.payments.cash ? ((data.payments.cash / data.payments.total) * 100).toFixed(2): '0'}%</small></small>
                    </p>
                </Col>
        
            </Row>

        </CardBody>
    </Card>
)

export default DashboardOverview