import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Progress } from "reactstrap";

import formatText from 'utils/formatText';

const CommunicationLiveQueues = ({ data }) => {

    const calls = data.calls;

    let averageAbandonTime = parseFloat((calls.total_time_till_abandoned / calls.total).toFixed(2))
    let averageConnectedTime = parseFloat((calls.total_time_till_connected / calls.total).toFixed(2))

    let inboundPercent   = parseFloat(((calls.inbound / calls.total) * 100).toFixed(2))
    let outboundPercent   = parseFloat(((calls.outbound / calls.total) * 100).toFixed(2))
    let serviceLevelPercent   = parseFloat(((calls.service_level / calls.inbound) * 100).toFixed(2))

    let completedPercent      = parseFloat(((calls.completed / calls.total) * 100).toFixed(2))
    let abandonedPercent      = parseFloat(((calls.abandoned / calls.total) * 100).toFixed(2))
    let shortAbandonedPercent      = parseFloat(((calls.short_abandoned / calls.total) * 100).toFixed(2))
    let voicemailPercent      = parseFloat(((calls.voicemail / calls.total) * 100).toFixed(2))
    let routedOutPercent      = parseFloat(((calls.routed_out / calls.total) * 100).toFixed(2))
    let inProgressPercent     = parseFloat(((calls.in_progress / calls.total) * 100).toFixed(2))
    let failedPercent         = parseFloat(((calls.failed / calls.total) * 100).toFixed(2))
    
    let overflowedPercent     = parseFloat(((calls.overflowed / calls.total) * 100).toFixed(2))
    let queueOverflowedPercent     = parseFloat(((calls.queue_overflowed / calls.total) * 100).toFixed(2))
    
    if(Number.isNaN(inboundPercent)) inboundPercent = '-'
    if(Number.isNaN(outboundPercent)) outboundPercent = '-'
    if(Number.isNaN(serviceLevelPercent)) serviceLevelPercent = '-'
    if(Number.isNaN(completedPercent)) completedPercent = '-'
    if(Number.isNaN(abandonedPercent)) abandonedPercent = '-'
    if(Number.isNaN(shortAbandonedPercent)) shortAbandonedPercent = '-'
    if(Number.isNaN(voicemailPercent)) voicemailPercent = '-'
    if(Number.isNaN(routedOutPercent)) routedOutPercent = '-'
    if(Number.isNaN(inProgressPercent)) inProgressPercent = '-'
    if(Number.isNaN(failedPercent)) failedPercent = '-'
    if(Number.isNaN(overflowedPercent)) overflowedPercent = '-'
    if(Number.isNaN(queueOverflowedPercent)) queueOverflowedPercent = '-'

    return (
        <Card 
            style={{overflow: 'hidden'}}
            className={`card-color card-primary  table-fixed table-no-stripes z-depth `}
        >
            <CardHeader >
                <CardTitle className="mb-0">Overall Service Levels</CardTitle>

            </CardHeader>

            <CardBody>
                <div className="progress-wrapper mt-0 pt-0">
                    <div className="progress-info mb-2 mt-0">
                        <div className="progress-label mb-0">
                            <span className="py-1">Service Level</span>
                        </div>
                        <div className="progress-percentage">
                            <span className="text-sm">{serviceLevelPercent === '-' ? 0 : serviceLevelPercent}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={serviceLevelPercent === '-' ? 0 : serviceLevelPercent} color={'default'} className="mb-2" />
                </div>
            </CardBody>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: 200}}>Metrics</th>
                            <th className="text-right">Total</th>
                            <th className="text-right">%</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Inbound Service Level</td>
                            <td className="text-right">{calls.service_level}</td>
                            <td className="text-right">{serviceLevelPercent === '-' ? 0 : serviceLevelPercent.toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Calls Inbound</td>
                            <td className="text-right">{calls.inbound}</td>
                            <td className="text-right">{inboundPercent === '-' ? 0 : inboundPercent.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Calls Outbound</td>
                            <td className="text-right">{calls.outbound}</td>
                            <td className="text-right">{outboundPercent === '-' ? 0 : outboundPercent.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Calls Answered</td>
                            <td className="text-right">{calls.completed}</td>
                            <td className="text-right">{completedPercent === '-' ? 0 : completedPercent.toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Calls Abandoned (Long)</td>
                            <td className="text-right">{calls.abandoned}</td>
                            <td className="text-right">{abandonedPercent === '-' ? 0 : abandonedPercent.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Calls Abandoned (Short)</td>
                            <td className="text-right">{calls.short_abandoned}</td>
                            <td className="text-right">{shortAbandonedPercent === '-' ? 0 : shortAbandonedPercent.toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Calls Went To Voicemail</td>
                            <td className="text-right">{calls.voicemail}</td>
                            <td className="text-right">{voicemailPercent === '-' ? 0 : voicemailPercent.toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Calls Routed Out</td>
                            <td className="text-right">{calls.routed_out}</td>
                            <td className="text-right">{routedOutPercent === '-' ? 0 : routedOutPercent.toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Calls In Progress</td>
                            <td className="text-right">{calls.in_progress}</td>
                            <td className="text-right">{inProgressPercent === '-' ? 0 : inProgressPercent.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Calls Failed</td>
                            <td className="text-right">{calls.failed}</td>
                            <td className="text-right">{failedPercent === '-' ? 0 : failedPercent.toFixed(2)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: 200}}>Overflows</th>
                            <th className="text-right">Total</th>
                            <th className="text-right">%</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Calls Overflowed</td>
                            <td className="text-right">{calls.overflowed}</td>
                            <td className="text-right">{overflowedPercent === '-' ? 0 : overflowedPercent.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Queues Overflowed</td>
                            <td className="text-right">{calls.queue_overflowed}</td>
                            <td className="text-right">{queueOverflowedPercent === '-' ? 0 : overflowedPercent.toFixed(2)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: 200}}>Timelines</th>
                            <th className="text-right">%</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Avg. Time Till Abandoned</td>
                            <td className="text-right">{formatText(averageAbandonTime).secondsToTime()}</td>
                        </tr>

                        <tr>
                            <td>Avg. Time Till Connected</td>
                            <td className="text-right">{formatText(averageConnectedTime).secondsToTime()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* <CardFooter className="bg-se">
                <p className="text-sm mb-0">{} Total</p>
            </CardFooter>  */}
        </Card>
    )



}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(CommunicationLiveQueues);
