import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";
import { formatCurrency } from 'utils/currency';

const DashboardOverview = ({ data }) => (
    <Card>
        <CardHeader>
            <CardTitle className='mb-0 text-ceter'>Division Health</CardTitle>
        </CardHeader>

        <CardBody className='text-center p-0' style={{overflow: 'hidden'}}>

            <Row>
                <Col className={`border-right py-3`}>
                    <h3>Unique Leads</h3>
                    <p className='mb-0 display-4'>{data.uniqueLeads}</p>
                </Col>
                <Col className={`border-right py-3`}>
                    <h3>Total Leads</h3>
                    <p className='mb-0 display-4'>{data.leads.length}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Successful Payments</h3>
                    <p className='mb-0 display-4'>{formatCurrency(data.payments.succeeded_amount)}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>New Matters</h3>
                    <p className='mb-0 display-4'>{data.newMatters}</p>
                </Col>
                
                <Col className='border-right py-3'>
                    <h3>Matter Progress</h3>
                    <p className='mb-0 display-4'>{data.matterProgressLogs.total_percent}%</p>
                </Col>

            </Row>

        </CardBody>
    </Card>
)

export default DashboardOverview