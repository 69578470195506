import * as actionTypes from '../../actions';
import store from 'store';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import { toggleAlertBS } from 'store/functions/system/system';

const cookies = new Cookies();


export const setSelectedDivision = (division) => {

    // if(division === 'all') {
    //     cookies.set('archk-selected-division', '', { path: '/' });
    //     return   store.dispatch({
    //         type: actionTypes.SET_STATE,
    //         payload: { selected_division: {} },
    //     });
    // }

    const division_id = division ? division : cookies.get('archk-selected-division')
    const divisions = store.getState().divisions;
    let foundDivision = divisions.find(d => d._id === division_id);

    if(foundDivision) cookies.set('archk-selected-division', foundDivision._id, { path: '/' });
    if(!foundDivision) foundDivision = divisions[0]

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: { selected_division: foundDivision ? foundDivision  : {} },
    });

}

export const addEmailEditor = (payload) => {
    const state = store.getState().state
    if(state.email_editors.length >= 2) return toggleAlertBS('info', 'No more than 2 email editors may be open at once')
    if(state.on_call._id && state.email_editors.length >= 1) return toggleAlertBS('info', 'No more than 1 email editors may be open while on call')
    store.dispatch({
        type: actionTypes.ADD_EMAIL_EDITOR,
        payload: {
            uuid: uuidv4(),
            subject: payload.subject,
            to: payload.to,
            contact: payload.contact,
        },
    });
}

export const removeEmailEditor = (uuid) => {
    store.dispatch({
        type: actionTypes.REMOVE_EMAIL_EDITOR,
        payload: uuid
    });
}

export const setOnCall = (params) => {

    document.body.classList.add('archk-bg-on-call')

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: {
            on_call: {
                _id: params._id || 'test'
            }
        }
    });
}

export const setOffCall = () => {
    document.body.classList.remove('archk-bg-on-call')

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: {
            on_call: {}
        }
    });
}
