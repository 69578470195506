import { useState } from "react";
import { connect } from 'react-redux';

import moment from 'moment';
import { Badge } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A';

import api from 'api';
import prettyBytes from 'pretty-bytes';

import { onDownloadDocument } from 'utils/documents';

const MattersAll = ({viewing_user}) => {
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        const filter = {deleted: false, created_at: { $gte: Math.floor(new Date() / 1000) - ( 86400 * 30 ) }, uploaded_by: viewing_user._id }
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await api.documents.search({ ...params, isCSV, filter })
        
        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "matter._id",
            text: 'Matter',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                if(!row.matter) return 'hi'
                return (
                    <div>
                        <div><A href={`/matters/${row.matter.id}`} className="text-dark font-weight-bold">{row.matter.name}</A></div>
                    </div>
                )
            },
        },
        {
            dataField: "matter.name",
            text: 'Document',
            formatter: (cell, row) => {

                if(row.name === 'no data') return '-'
                return (
                    <div>
                        <div>{row.name}</div>
                    </div>
                )
            },
        },
        {
            dataField: "size",
            text: 'Size',
            formatter: (cell, row) => {

                if(row.name === 'no data') return '-'
                return (
                    <div>
                        <div>{prettyBytes(row.size)}</div>
                    </div>
                )
            },
        },
        {
            dataField: "status",
            text: 'Status',
            formatter: (cell, row) => {

                if(row.name === 'no data') return '-'
                return (
                    <div>
                        {row.status === 'uploading' ? (
                            <Badge color="info">Uploading</Badge>
                        ) : row.status === 'succeeded' ? (
                            <Badge color="success">Succeeded</Badge>
                        ) : (
                            <Badge color="danger">Failed</Badge>
                        )}
                    </div>
                )
            },
        },
        {
            dataField: "created_at",
            text: 'Date',
            formatter: (cell, row) => {

                if(row.name === 'no data') return '-'
                return (
                    <div>
                        <div>{moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')}</div>
                    </div>
                )
            },
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <button onClick={() => onDownloadDocument(row)} className="btn btn-sm btn-success"><i className="fas fa-download mr-2" /> Download</button>
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} />

    return (
        <Table
            hideSearch={true}
            tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
            placeholder="Search Matters..."
            query={query}
            columns={columns}
            data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
            totalDocuments={tableData.total_documents}
            sizePerPage={sizePerPage}
            onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
        />
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
