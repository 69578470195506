import React from 'react';

import { Card, CardHeader, CardTitle } from "reactstrap";
import { formatCurrency } from 'utils/currency';

const DashboardOverview = ({ data }) => (
    <Card>
        <CardHeader>
            <CardTitle className='mb-0'>Category Changes</CardTitle>
        </CardHeader>

        <div className="table-responsive">
            <table className="table border">
                <thead>
                    <tr>
                        <th>Workflow</th>
                        <th>Step Category</th>
                        <th>Matters Added</th>
                        <th className='text-right'>Estimated Value</th>
                    </tr>
                </thead>
                <tbody>
                    {data.categories.map(c => (
                        <React.Fragment key={c.workflow._id}>
                            <tr>
                                <td className='text-dark text-uppercase font-weight-bold'>{c.workflow.name}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {c.categories.map(cc => (
                                <tr key={cc._id}>
                                    <td></td>
                                    <td>{cc.name}</td>
                                    <td>{cc.total ? cc.total : 0}</td>
                                    <td className='text-right'>{formatCurrency(cc.value)}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    </Card>
)

export default DashboardOverview