
import * as actionTypes from '../../actions';
import store from '../../index';

export const openDocumentCenter = async({matter, task, matter_document}) => {
    if(!matter) return console.warn('A Matter must be passed to openDocumentCenter')

    store.dispatch({ type: actionTypes.SET_DOCUMENT_CENTER, payload: { matter, task, matter_document, open: true } });
}

export const closeDocumentCenter = async(newDocs) => {

    if(newDocs && newDocs.length) {
        const onUpload = store.getState().document_center.onUpload
        console.log(onUpload)
    }

    store.dispatch({ type: actionTypes.SET_DOCUMENT_CENTER, payload: { 
        matter: null,
        task: null,
        matter_document: null,
        open: false 
    } });
}

