import { useCallback, useState, useEffect } from 'react';
import ReactSelectAsync from "components/functional/inputs/ReactSelectAsync"

import api from 'api';

const SearchCollections = ({filter, onChange, value, title, formGroup, hideSelected, collection, addNull, description, includeFields, onBlur, placeholder, menuPlacement, disabled, hideTitle, showEnhanced, sort}) => {

    const search = useCallback(async (query) => new Promise (async resolve => {
        const data = await api[collection].search({
            searchString: query,
            filter: filter ? filter : {},
            limit: 20,
            sort: sort ? sort : undefined
        })

        if(addNull && data.data && data.data.documents) {
            data.data.documents.unshift({
                _id: null,
                name: <span><i className="fas fa-times mr-2 text-danger" /> None</span>
            })
        }
        return resolve(data)
    }), [filter, collection, addNull, sort])

    return (
        <ReactSelectAsync 
            hideSelected={hideSelected}
            formGroup={formGroup || true}
            title={title || 'Search'}
            description={description}
            value={value}
            onChange={onChange}
            search={search}
            includeFields={includeFields}
            onBlur={onBlur}
            placeholder={placeholder}
            menuPlacement={menuPlacement}
            disabled={disabled}
            hideTitle={hideTitle}
            showEnhanced={showEnhanced}
            collection={collection}
        /> 
    )
}

// only rerender this when collection changes to prevent prop changes from firing hundreds of searches
const Wrapper = ({filter, onChange, value, title, formGroup, hideSelected, collection, addNull, description, includeFields, onBlur, placeholder, menuPlacement, disabled, hideTitle, showEnhanced, sort}) => {

    const [_collection, setCollection] = useState(collection);
    const [_filter, setFilter] = useState(filter);
    const [_title, setTitle] = useState(title);
    const [_formGroup, setFormGroup] = useState(formGroup);
    const [_hideSelected, setHideSelected] = useState(hideSelected);
    const [_description, setDescription] = useState(description);
    const [_includeFields, setIncludeFields] = useState(includeFields);
    const [_menuPlacement, setMenuPlacement] = useState(menuPlacement);
    const [_disabled, setDisabled] = useState(disabled);
    const [_hideTitle, setHideTitle] = useState(hideTitle);

    useEffect(() => {
        setCollection(collection)
        setFilter(filter)
        setTitle(title)
        setFormGroup(formGroup)
        setHideSelected(hideSelected)
        setDescription(description)
        setIncludeFields(includeFields)
        setMenuPlacement(menuPlacement)
        setHideTitle(hideTitle)
        // eslint-disable-next-line
    }, [collection])

    return (
        <SearchCollections 
            menuPlacement={_menuPlacement}
            filter={_filter}
            title={_title}
            formGroup={_formGroup}
            hideSelected={_hideSelected}
            collection={_collection}
            description={_description}
            includeFields={_includeFields}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            addNull={addNull}
            onBlur={onBlur}
            disabled={_disabled}
            hideTitle={_hideTitle}
            showEnhanced={showEnhanced}
            sort={sort}
        />
    )

}

export default Wrapper;