import { Col, Container, Row } from "reactstrap";

const ContactsNavigation = ({tab, setTab, tabs, title, description, actionComponent, background, leftColSize}) => {

    return (

        <div className={`${background ? background : 'bg-secondary'} border-bottom archk-navigation mb-4`}>
            <Container fluid>
                <div className="archk-navigation-top pl-2">
                    <Row>
                        <Col md={leftColSize ? leftColSize : 8} className="align-self-center">
                            {title ? <h3 className="mb--2 display-4">{title}</h3> : ''}
                            {description ? <p className="mb-0 text-sm text-muted">{description}</p> : ''}
                            
                        </Col>
                        <Col md={leftColSize ? 12 - leftColSize : 4} className="text-right align-self-center">
                            {actionComponent ? (
                                actionComponent
                            ) : null}
                        </Col>
                    </Row>
                </div>

                {tabs && tabs.length ? (
                    <div>
                        <ul className="pl-0 mb-0">
                            {tabs.map((t, i) => (
                                <li 
                                    key={i}
                                    onClick={() => setTab(t.value)} 
                                    className={`d-inline-block ${tab === t.value ? 'active' : ''}`}
                                >
                                    {t.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </Container>
        </div>

    );

}

export default ContactsNavigation;