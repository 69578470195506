import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardFooter, Col, Container, Row } from "reactstrap";


import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Navigation from '../Navigation'

import ApiError from "components/markup/layout/ApiError";
import Circle from "components/markup/loading/Circle";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';
import formatText from 'utils/formatText';

import DatePicker from 'react-datepicker'

import { toast } from 'react-toastify'

const badgeClasses = "rounded d-inline-block py-1 text-capitalize px-3"

const MattersAll = ({socket, match}) => {


    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));


    const [ directOffers, setDirectOffers ] = useState(null);
    const [ queueOffers, setQueueOffers ] = useState(null);
    const [ allOffers, setAllOffers ] = useState(null);

    const [ queuePercents, setQueuePercents ] = useState(null)
    const [ directPercents, setDirectPercents ] = useState(null)
    const [ totalPercents, setTotalPercents ] = useState(null)

    const [ err, setErr ] = useState(false)

    const getPercent = useCallback((num1, num2) => {
        if(!num1 || !num2) return '-'
        return Math.floor((num1 / num2) * 100).toFixed(2) + '%'

    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async () => {
        const unixStart = parseInt(moment(start).format('X'))
        const unixEnd = parseInt(moment(end).endOf('day').format('X'))

        if(unixStart >= unixEnd) return toast.info(`Start date must be before the selected end date`)
        if(unixEnd > unixStart + ( 86400 * 32 )) return toast.info(`Selected range must span no more than 1 month.`)

        const data = await api.call_offers.findByUser(match.params.user, unixStart, unixEnd)
        if(!data.data) return setErr(data.message) 

        // fixed bug in deleted calls and queues
        let _offers = data.data;
        _offers = _offers.filter(o => o.call)

        const queue = _offers.filter(o => o.type === 'queue');
        const direct = _offers.filter(o => o.type === 'direct');

        let _queuePercents = {
            answered: 0,
            ignored: 0,
            declined: 0,
            answered_by_other: 0,
            answered_total_ring_time: 0,
        }
        let _directPercents = {
            answered: 0,
            ignored: 0,
            declined: 0,
            answered_by_other: 0,
            answered_total_ring_time: 0,
        }
        let _totalPercents = {
            answered: 0,
            ignored: 0,
            declined: 0,
            answered_by_other: 0,
            answered_total_ring_time: 0,
        }

        _offers.forEach(o => _totalPercents[o.status]++)

        console.log(_offers)

        queue.forEach(q => {
            _queuePercents[q.status]++

            if(q.status === 'answered') {
                _queuePercents.answered_total_ring_time += parseInt(q.ring_time)
                _totalPercents.answered_total_ring_time += parseInt(q.ring_time)
            }
        })

        direct.forEach(q => {
            _directPercents[q.status]++
            if(q.status === 'answered') {
                _directPercents.answered_total_ring_time += parseInt(q.ring_time)
                _totalPercents.answered_total_ring_time += parseInt(q.ring_time)
            }
        })

        setDirectOffers(direct)
        setQueueOffers(queue)
        setAllOffers(_offers)

        setQueuePercents(_queuePercents)
        setDirectPercents(_directPercents)
        setTotalPercents(_totalPercents)
    }, [match.params.user, start, end])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {

        socket.on('CALL_CENTER.CALL_OFFER_UPDATED', fetchData)
        socket.on('CALL_CENTER.CALL_OFFER_CREATED', fetchData)

        return () => {
            socket.off('CALL_CENTER.CALL_OFFER_UPDATED', fetchData)
            socket.off('CALL_CENTER.CALL_OFFER_CREATED', fetchData)
        }

    }, [socket, fetchData])


    if(err) return <ApiError err={err} className="mx-4 py-3" />
    if(!allOffers) return <Circle />

    return (

        <>

        <Helmet>
            <title>User Offers</title>
            <meta name="description" content="User Offers" />
        </Helmet>

        <HeaderNavigation 
            title={<ObjectFinder collection="users" _id={match.params.user} />}
            description="Call Offers"
            leftColSize={4}
            actionComponent={(
                <Row>
                    <Col md={6}></Col>
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Select Start Date</p>
                        <DatePicker 
                            selected={start} 
                            onChange={(date) => setStart(date)} 
                        />
                    </Col>
                    <Col md={3}>
                        <p className="text-sm mb-0">Select End Date</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => setEnd(date)} 
                        />
                    </Col>
                
                </Row>
            )}
        />

        <Container fluid>

            <Navigation />

            <Row>
                <Col md={4}>
                    <Card>
                        <div className="table-responsive">
                            <table className="table">

                                <thead>
                                    <tr>
                                        <th><b className="text-dark"><i className="fas fa-mobile text-purple mr-2" /> All Offers</b></th>
                                        <th className="text-center">Percent</th>
                                        <th className="text-right">Total: {allOffers.length} </th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td style={{width: '33.3%'}}>Answered</td>
                                        <td style={{width: '33.3%'}} className="text-center text-success">
                                            {getPercent(totalPercents.answered, allOffers.length)}
                                        </td>
                                        <td style={{width: '33.3%'}} className="text-right">{totalPercents.answered}</td>
                                    </tr>
                                    <tr>
                                        <td>Declined</td>
                                        <td className="text-center text-danger">
                                            {getPercent(totalPercents.declined, allOffers.length)}
                                        </td>
                                        <td className="text-right">{totalPercents.declined}</td>
                                    </tr>
                                    <tr>
                                        <td>Ignored</td>
                                        <td className="text-center text-warning">
                                            {getPercent(totalPercents.ignored, allOffers.length)}
                                        </td>
                                        <td className="text-right">{totalPercents.ignored}</td>
                                    </tr>
                                    <tr>
                                        <td>Other Answered</td>
                                        <td className="text-center text-info">
                                            {getPercent(totalPercents.answered_by_other, allOffers.length)}
                                        </td>
                                        <td className="text-right">{totalPercents.answered_by_other}</td>
                                    </tr>
                                    <tr>
                                        <td>Average Ring Time</td>
                                        <td></td>
                                        <td className="text-right">
                                            {
                                                totalPercents.answered_total_ring_time && totalPercents.answered ? 
                                                (totalPercents.answered_total_ring_time / totalPercents.answered).toFixed(2) : 
                                                0
                                            } 
                                            {' '} Seconds
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <div className="table-responsive">
                            <table className="table">

                                <thead>
                                    <tr>
                                        <th><b className="text-dark"><i className="fas fa-user text-purple mr-2" /> Direct Offers</b></th>
                                        <th className="text-center">Percent</th>
                                        <th className="text-right">Total: {directOffers.length} </th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td style={{width: '33.3%'}}>Answered</td>
                                        <td style={{width: '33.3%'}} className="text-center text-success">
                                            {getPercent(directPercents.answered, directOffers.length)}
                                        </td>
                                        <td style={{width: '33.3%'}} className="text-right">{directPercents.answered}</td>
                                    </tr>
                                    <tr>
                                        <td>Declined</td>
                                        <td className="text-center text-danger">
                                            {getPercent(directPercents.answered, directOffers.length)}
                                        </td>
                                        <td className="text-right">{directPercents.declined}</td>
                                    </tr>
                                    <tr>
                                        <td>Ignored</td>
                                        <td className="text-center text-warning">
                                            {getPercent(directPercents.ignored, directOffers.length)}
                                        </td>
                                        <td className="text-right">{directPercents.ignored}</td>
                                    </tr>
                                    <tr>
                                        <td>Other Answered</td>
                                        <td className="text-center text-info">
                                            {getPercent(directPercents.answered_by_other, directOffers.length)}
                                        </td>
                                        <td className="text-right">{directPercents.answered_by_other}</td>
                                    </tr>
                                    <tr>
                                        <td>Average Ring Time</td>
                                        <td></td>
                                        <td className="text-right">
                                            {
                                                directPercents.answered_total_ring_time && directPercents.answered ? 
                                                (directPercents.answered_total_ring_time / directPercents.answered).toFixed(2) : 
                                                0
                                            } 
                                            {' '} Seconds
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <div className="table-responsive">
                            <table className="table">

                                <thead>
                                    <tr>
                                        <th><b className="text-dark"><i className="fas fa-headset text-purple mr-2 " /> Queue Offers</b></th>
                                        <th className="text-center">Percent</th>
                                        <th className="text-right">Total: {queueOffers.length} </th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td style={{width: '33.3%'}}>Answered</td>
                                        <td style={{width: '33.3%'}} className="text-center text-success">
                                            {getPercent(queuePercents.answered, queueOffers.length)}
                                        </td>
                                        <td style={{width: '33.3%'}} className="text-right">{queuePercents.answered}</td>
                                    </tr>
                                    <tr>
                                        <td>Declined</td>
                                        <td className="text-center text-danger">
                                            {getPercent(queuePercents.declined, queueOffers.length)}
                                        </td>
                                        <td className="text-right">{queuePercents.declined}</td>
                                    </tr>
                                    <tr>
                                        <td>Ignored</td>
                                        <td className="text-center text-warning">
                                            {getPercent(queuePercents.ignored, queueOffers.length)}
                                        </td>
                                        <td className="text-right">{queuePercents.ignored}</td>
                                    </tr>
                                    <tr>
                                        <td>Other Answered</td>
                                        <td className="text-center text-info">
                                            {getPercent(queuePercents.answered_by_other, queueOffers.length)}
                                        </td>
                                        <td className="text-right">{queuePercents.answered_by_other}</td>
                                    </tr>
                                    <tr>
                                        <td>Average Ring Time</td>
                                        <td></td>
                                        <td className="text-right">
                                        
                                            {
                                                queuePercents.answered_total_ring_time && queuePercents.answered ? 
                                                (queuePercents.answered_total_ring_time / queuePercents.answered).toFixed(2) : 
                                                0
                                            } 
                                            {' '} Seconds
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Col>
            </Row>


            <Card className="card-color card-primary table-fixed table-no-stripes z-depth-">

                {/* <CardHeader>
                    <CardTitle className="text-sm mb-0">My Offers</CardTitle>
                </CardHeader> */}

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Contact</th>
                                <th>Type</th>
                                <th>Division</th>
                                <th>Duration</th>
                                <th>Answered By</th>
                                <th>Ring Time</th>
                                <th className="text-center">Action</th>
                                <th className="text-center">Call Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {allOffers.length ? allOffers.map(offer => {

                                const foundLeg = offer.call ? offer.call.call_legs.find(leg => leg.user === match.params.user) : null
                                const duration = foundLeg ? foundLeg.duration : offer.call.duration;

                                return (
                                    <tr key={offer._id + "_" + offer.call.status}>
                                        <td>
                                            <div>{moment.unix(offer.created_at).format('MM/DD/YYYY')}</div>
                                            <div>{moment.unix(offer.created_at).format('h:mm:ss A')}</div>
                                        </td>
                                        <td>
                                            <div><ObjectFinder collection="contacts" _id={offer.call.contact} /></div>
                                            <div>{formatText(offer.call.from).phone()}</div>
                                        </td>
                                        <td>
                                            {offer.type === 'direct' ? 'Direct' : 'Queue'}
                                            {offer.type !== 'direct' && offer.call.call_queue ? (
                                                <div>
                                                    <ObjectFinder collection="call_queues" _id={offer.call.call_queue} />
                                                </div>
                                            ) : ''}
                                        </td>
                                     
                                        <td>
                                            <ObjectFinder collection="divisions" _id={offer.call.division} />
                                        </td>
                                        <td>
                                            {formatText(duration).secondsToTime()}
                                        </td>
                                        <td>
                                            {offer.answered_by ? <ObjectFinder collection="users" _id={offer.answered_by} /> : '-'}
                                        </td>
                                        <td>
                                            {formatText(offer.updated_at - offer.created_at).secondsToTime()}
                                        </td>
                                        <td className="text-center">
                                            {offer.status === 'answered' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-success bg-faded-success`}>{offer.status}</span>
                                            ) : offer.status === 'declined' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-danger bg-faded-danger`}>{offer.status}</span>
                                            ) : offer.status === 'ignored' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-warning bg-faded-warning`}>{offer.status}</span>
                                            ) : offer.status === 'answered_by_other' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-info bg-faded-info`}>Other Answered</span>
                                            ) : ''}
                                        </td>
                                        <td className="text-center">
                                            {offer.call.status === 'abandoned' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-danger bg-faded-danger`}>{offer.call.abandon_type === 'short' ? 'Short Abandonded' : 'Abandoned'} </span>
                                            ) : offer.call.status === 'completed' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-success bg-faded-success`}>Connected</span>
                                            ) : offer.call.status === 'voicemail' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-success bg-faded-info`}>Voicemail</span>
                                            ) : offer.call.status === 'routed_out' ? (
                                                <span style={styles.badge} className={`${badgeClasses} border-success bg-faded-info`}>Routed Out</span>
                                            ) : (
                                                <span style={styles.badge} className={`${badgeClasses} border-info bg-faded-info`}>Processing</span>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <tr></tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter className="bg-se">
                    <p className="text-sm mb-0">{allOffers.length} Total</p>
                </CardFooter>

            </Card>


        </Container>

        </>
    )

}

const styles = {
    badge: {
        width: 140
    }
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
