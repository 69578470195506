import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import A from 'components/markup/links/A'

import { Card, Container, Badge, Row, Col } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";

import api from 'api';

import Navigation from './Navigation'

import DatePicker from 'react-datepicker'

import ViewSigningDocument from "./components/ViewSigningDocument";

import ModalToggler from 'components/functional/modals/Toggler'
import SearchCollections from "components/system/Search/Collections";



import { toast } from 'react-toastify'


const SupervisorSignatureAnalytics = ({DOMAINS, selected_division}) => {

    const signedDocsOnly = window.location.href.includes('signed');

    const [ sentBy, setSentBy ] = useState(null);
    const [ signingTemplate, setSigningTemplate ] = useState(null);
    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));


    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    const onSetStart = useCallback((s) => {
        const _start = parseInt(moment(s).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newEnd = moment(s).endOf('month').toDate();
            setEnd(newEnd)
        }
        setStart(s)
    }, [end])
    
    const onSetEnd = useCallback((e) => {
        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(e).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newStart = moment(e).startOf('month').toDate();
            setStart(newStart)
        }
        setEnd(e)
    }, [start])

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.sort = { created_at: -1 }

        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_start > _end) return toast.info(`Search start date cannot be greater than end date.`)
        if(_end > (_start + ( 86400 * 32 ))) return toast.info(`Search range must span no more than 1 month`)

        params.filter.created_at = { $gte: _start, $lt: _end }

        if(sentBy) params.filter.sent_by = sentBy;
        if(signingTemplate) params.filter.signing_template = signingTemplate;
        if(signedDocsOnly) params.filter.finalized_on = { $gt: 0 }

        const query = await api._supervisor.signatures.analytics(selected_division._id, { ...params, isCSV })

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents })
        setErr(query.message)
    }), [selected_division._id, sizePerPage, start, end, signingTemplate, sentBy, signedDocsOnly])

    useEffect(() => {
        query()
        // eslint-disable-next-line
    }, [start, end, signingTemplate, sentBy])

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerStyle: { width: 200     },
            headerFormatter: () => {
                return <div><div>Matter</div></div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <A href={`${DOMAINS.APP}/matters/${row.matter}?docsTab=Signing&tab=Documents`} className="text-dark font-weight-bold">
                            <ObjectFinder collection="matters" _id={row.matter} />
                        </A>
                        
                    </div>
                )
            },
        },
        {
            dataField: "signing_template",
            text: 'Template',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    row.signing_template ? <ObjectFinder collection="signing_templates" _id={row.signing_template} /> : ''
                )
            )
        },
        {
            dataField: "status",
            text: 'Status',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    row.status === 'sent' || row.status === 'in progress' ? (
                        <Badge style={{width: 100}} color="info">{row.status}</Badge>
                    ) : row.status === 'void' ? (
                        <Badge style={{width: 100}} color="danger">{row.status}</Badge>
                    ) : (
                        <Badge style={{width: 100}} color="success">{row.status}</Badge>
                    )
                )
            )
        },

        { 
            dataField: "sent_by",
            text: 'Sent By',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <ObjectFinder collection="users" _id={row.sent_by} />
                )
            )
        },
        { 
            dataField: "created_at",
            text: 'Sent At',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')
                )
            )
        },
        { 
            dataField: "finalized_on",
            text: 'Finalized At',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    row.finalized_on ? moment.unix(row.finalized_on).format('MM/DD/YYYY h:mm A') : '-'
                )
            )
        },

        // {
        //     dataField: "roles",
        //     text: 'Users',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : !row.roles ? '' :
        //         row.roles.map((c, i) => (
        //             <span key={i}> {i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={c.user} /></span>
        //         ))
        //     )
        // },
       
        // {
        //     dataField: "division",
        //     text: 'Division / Matter Type',
        //     headerFormatter: () => (
        //         <div><div>Division /</div><div>Matter Type</div></div>
        //     ),
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : (
        //             <div>
        //                 <div><ObjectFinder collection="divisions" _id={row.division} /></div>
        //                 <div><ObjectFinder collection="workflows" _id={row.workflow} /></div>
        //             </div>
        //         )
        //     )
        // },
        // {
        //     dataField: "current_step_category",
        //     text: 'Step / Category',
        //     headerFormatter: () => (
        //         <div><div>Category /</div><div>Disposition</div></div>
        //     ),
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : (
        //             <div>
        //                 <div>{row.current_step_name}</div>
        //                 <div>{row.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={row.workflow_step_category} /> : 'No Category'}</div>
        //             </div>
        //         )
        //     )
        // },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 140},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-eye',
                                    color: 'info', 
                                    wrapper: ModalToggler, 
                                    component: ViewSigningDocument,
                                    _id: row._id,
                                    tooltip: 'View Events'
                                },
                                { 
                                    icon: 'fas fa-link',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/matters/${row.matter}?docsTab=Signing&tab=Documents`,
                                    tooltip: 'Open Matter'
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = signedDocsOnly ? 'Signed Documents' : 'Signature Analytics'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description="List View"
            leftColSize={4}
            actionComponent={(
                <Row>
                    <Col md={3}>
                        <SearchCollections
                            collection={'users'}
                            title={<span>Select Sending user</span>}
                            placeholder="..."
                            value={sentBy}
                            onChange={(obj) => setSentBy(obj.value)}
                            isClearable={true}
                            filter={{
                                divisions: { $in: [selected_division._id] },
                            }}
                        /> 
                    </Col>
                    <Col md={3}>
                        <SearchCollections
                            collection={'signing_templates'}
                            title={<span>Select Template</span>}
                            placeholder="..."
                            value={signingTemplate}
                            onChange={(obj) => setSigningTemplate(obj.value)}
                            isClearable={true}
                            filter={{
                                division: { $in: [selected_division._id] },
                            }}
                        /> 
                    </Col>
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Select Start Date</p>
                        <DatePicker 
                            selected={start} 
                            onChange={(date) => onSetStart(date)} 
                        />
                    </Col>
                    <Col md={3}>
                        <p className="text-sm mb-0">Select End Date</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => onSetEnd(date)} 
                        />
                    </Col>
                
                </Row>
            )}
        />

        <Container fluid>

            <Navigation />
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(SupervisorSignatureAnalytics);
