import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Badge, Card, Container } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Navigation from '../Navigation';

import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A';

import api from 'api';


const CommunicationSelectUser = ({match, viewing_user, selected_division}) => {

    const type = match.params.type;

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(selected_division._id) params.filter.division = selected_division._id

        const query = await api.users.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "display_name",
            text: 'Name',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><Link to={`/communication/user/${row._id}`} className="text-dark font-weight-bold">{row.display_name}</Link></div>
                    </div>
                )
            },
        },
        {
            dataField: "title",
            text: 'Title',
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                return row.title
            },
        },
        {
            dataField: "email",
            text: 'Email',
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                return row.email
            },
        },
        {
            dataField: "agent_can_receive_calls",
            text: 'Active Phone Connection',
            headerStyle: { textAlign: 'center' },
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                return (
                    <div className="text-center">
                        {row.agent_can_receive_calls ? <Badge style={{width: 80}} color="success">Yes</Badge> : <Badge style={{width: 80}} color="danger">No</Badge>}
                    </div>
                )
            },
        },
        {
            dataField: "agent_is_in_queue",
            text: 'Currently In Queue',
            headerStyle: { textAlign: 'center' },
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                return (
                    <div className="text-center">
                        {row.agent_can_receive_calls ? <Badge style={{width: 80}} color="success">Yes</Badge> : <Badge style={{width: 80}} color="danger">No</Badge>}
                    </div>
                )

            },
        },
    
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/communication/user/${row._id}`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 
        type === 'mine' ? 'Assigned Matters' : 
        type === 'new' ? 'New Matters' : 
        type === 'bookmarked' ? 'Bookmarked Matters' : 
        'All Matters'
    const description = 
        type === 'mine' ? 'All Matters Assigned To Me' : 
        type === 'new' ? 'Matters Created in The Last 72 Hours' :
        type === 'bookmarked' ? 'Showing All My Current Bookmarked Matters' : 
        'Showing All Matter In The Selected Division'

    return (

        <>

        <Helmet>
            <title>Select User</title>
            <meta name="description" content="Select User" />
        </Helmet>

        <HeaderNavigation 
            title="User Report"
            description={`${selected_division.name ? selected_division.name : 'All'} Users`}
            actionComponent={(
                <div>
                    <h2>Date: {moment().format('MMM Do, YYYY')}</h2>
                </div>
            )}
        />

        <Container fluid>

            <Navigation />
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Users..."
                    query={query}
                    rowClasses={(row) => {
                        if(!row.roles) return ''
                        const isAssignedOnRole = row.roles.some(r => r.user === viewing_user._id)
                        return isAssignedOnRole ? 'row-mine' : '';
                    }}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CommunicationSelectUser);
