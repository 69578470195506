import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";

const DashboardOverview = ({ data }) => (
    <Card>
        <CardHeader>
            <CardTitle className='mb-0 text-ceter'>Support Tickets</CardTitle>
        </CardHeader>

        <CardBody className='text-center p-0' style={{overflow: 'hidden'}}>

            <Row>
                <Col className={`border-right py-3 ${data.supportTickets.unresolved  ? 'bg-warning text-white' : ''}`}>
                    <h3 className={data.supportTickets.unresolved ? 'text-white' : ''}>Unresolved</h3>
                    <p className='mb-0 display-4'>{data.supportTickets.unresolved}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Total Tickets Today</h3>
                    <p className='mb-0 display-4'>{data.supportTickets.total}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Call Tickets</h3>
                    <p className='mb-0 display-4'>{data.supportTickets.call}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Email Tickets</h3>
                    <p className='mb-0 display-4'>{data.supportTickets.email}</p>
                </Col>
                <Col className='py-3'>
                    <h3>Text Tickets</h3>
                    <p className='mb-0 display-4'>{data.supportTickets.text}</p>
                </Col>
            </Row>

        </CardBody>
    </Card>
)

export default DashboardOverview