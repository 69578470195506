import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import SearchCollections from "components/system/Search/Collections";
import ApiError from "components/markup/layout/ApiError";
import Circle from "components/markup/loading/Circle";

import api from 'api';

import { formatCurrency } from 'utils/currency';

import image from './image.svg';
import imageNotFound from './imageNotFound.svg';

const SupervisorPipelineBreakdown = ({selected_division, match}) => {
    const [ loading, setLoading ] = useState(false);

    const [data, setData] = useState(null)
    const [department, setDepartment] = useState(null)

    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async () => {
        if(!department) return;

        setLoading(true)
        const data = await api._supervisor.pipelineBreakdown(selected_division._id, { division_department: department })
        setLoading(false)
        if(!data.data) return setErr(data.message) 

        setData(data.data);

    }, [department, selected_division._id])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    return (

        <>

            <Helmet>
                <title>Pipeline Breakdown</title>
                <meta name="description" content="User Offers" />
            </Helmet>

            <HeaderNavigation 
                title="Pipeline Breakdown"
                description="Department Assignments"
                leftColSize={4}
                actionComponent={(
                    <Row>
                        <Col md={8}></Col>
                    
                        <Col md={4}>
                            <SearchCollections
                                title="Select Department"
                                collection={'division_departments'}
                                placeholder="Type To Search"
                                value={department}
                                onChange={(obj) => setDepartment(obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        </Col>
                    
                    </Row>
                )}
            />

            <Container fluid>

            

                {loading ? (
                    <Circle />
                ) : !data ? (
                    <div className="text-center py-6">
                        <img style={{width: 200}} src={image} alt="departments" />
                        <p className="font-weight-bold text-uppercase mt-5">Select A Department Above To View Metrics</p>
                    </div>
                ) : !data.length ? (
                    <div className="text-center py-6">
                        <img style={{width: 200}} src={imageNotFound} alt="departments" />
                        <p className="font-weight-bold text-uppercase mt-5">No Data Was Found For The Selected Division</p>
                    </div>
                ) : data.map((d, i) => (
                    <Card key={d.user}>

                        <CardHeader>
                            <CardTitle className="mb-0">{d.user_name}</CardTitle>
                        </CardHeader>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Step Category</th>
                                        <th className='text-center'>Assigned</th>
                                        <th className='text-center'>% Assigned</th>
                                        <th className='text-center'>Estimated Value</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr >
                                        <td className='text-dark font-weight-bold'>TOTAL ASSIGNED</td>
                                        <td className='text-center'>{d.total}</td>
                                        <td className='text-center'>{d.percent ? d.percent.toFixed(2) : 0}%</td>
                                        <td className='text-center'>{formatCurrency(d.value)}</td>
                                    </tr>
                                    
                                    {d.breakdown.map(dd => (
                                        <tr  key={dd.workflow_step_category}>
                                            <td className='pl-'>{dd.workflow_step_category_name}</td>
                                            <td className='pl- text-center'>{dd.total}</td>
                                            <td className='pl- text-center'>{dd.percent ? dd.percent.toFixed(2) : 0}%</td>
                                            <td className='text-center'>{formatCurrency(dd.value)}</td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                    </Card>
                ))}
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SupervisorPipelineBreakdown);
