import { connect } from 'react-redux';

import NavbarDesktop from './NavbarDesktop'


const Navbar = ({toggleSidenav}) => {

	return <NavbarDesktop toggleSidenav={toggleSidenav}  />

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Navbar);