import { useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import A from 'components/markup/links/A'

import { Card, Container } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";

import api from 'api';

const SupervisorMattersOverdue = ({row, DOMAINS}) => {

    const [hover, setHover] = useState(false)

    return (
        <div
            onMouseOver={() => setHover(true)} 
            onMouseOut={() => setHover(false)} 
            style={{position: 'relative'}}
        >
            <div>
                {row.incoming_communication_type === 'text' ? (
                    <b><i className="fas fa-mobile text-warning" /> </b>
                ) : row.incoming_communication_type === 'call' ? (
                    <b><i className="fa-solid fa-phone-volume text-success" /> </b>
                ) : (
                    <b><i className="fa-solid fa-envelope-open-text text-info" /> </b>
                )}
                {' '}
                {row.incoming_communication_snippet.length > 24 ? row.incoming_communication_snippet.slice(0,24) + '...' : row.incoming_communication_snippet}
            </div>
            <small>{moment.unix(row.incoming_communication_at).format('MMM Do, YYYY h:mm A')}</small>

            {hover ? (
                <A href={`${DOMAINS.APP}/matters/${row.id}`} className="text-dark font-weight-bold">
                <div   
                    className="bg-white z-depth-1 border p-3"
                    style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 300,

                        borderBottomLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                >
                    {row.incoming_communication_snippet}
                    <div><small>{moment.unix(row.incoming_communication_at).format('MMM Do, YYYY h:mm A')}</small></div>
                </div>
                </A>
            ) : ''}
            
        </div>
    )

}

const MattersAll = ({DOMAINS, selected_division}) => {
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};


        const query = await api._supervisor.mattersOverdue(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerStyle: { width: 200     },
            headerFormatter: () => {
                return <div><div>Matter</div></div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><A href={`${DOMAINS.APP}/matters/${row.id}`} className="text-dark font-weight-bold">
                            {row.current_step_overdue_at > 0 && row.current_step_overdue_at <= Math.floor(new Date() / 1000) ? <i className="fa-solid fa-clock text-warning mr-2" /> : ''}
                            {row.name}</A>
                        </div>
                        <div><A href={`${DOMAINS.APP}/matters/${row.id}`} className="text-muted">{row.last_note}</A></div>
                    </div>
                )
            },
        },
        {
            dataField: "current_step_overdue_at",
            text: 'Overdue At',

            formatter: (cell, row) => (
                row.name === 'no data' ? '' : moment.unix(row.current_step_overdue_at).format('MM/DD/YYYY hh:mm A')
            )
        },
        {
            dataField: "incoming_communication_snippet",
            text: 'Name',
            headerFormatter: () => {
                return <div>Last Incoming <i className="fas fa-comments ml-2" /></div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                if(!row.incoming_communication_snippet) return '-'
                return <SupervisorMattersOverdue row={row} DOMAINS={DOMAINS} />
            },
        },
      
        // {
        //     dataField: "contact",
        //     text: 'Contacts',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : !row.contacts ? '' :
        //         row.contacts.map((c, i) => (
        //             <Link key={c._id + i.toString()} to={`/clients/${row._id}`}>
        //                 <span> {i !== 0 ? ', ' : ''}<ObjectFinder collection="contacts" _id={c} /></span>
        //             </Link>
        //         ))
        //     )
        // },
        {
            dataField: "roles",
            text: 'Users',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.roles ? '' :
                row.roles.map((c, i) => (
                    <span key={i}> {i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={c.user} /></span>
                ))
            )
        },
       
        {
            dataField: "division",
            text: 'Division / Matter Type',
            headerFormatter: () => (
                <div><div>Division /</div><div>Matter Type</div></div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div><ObjectFinder collection="divisions" _id={row.division} /></div>
                        <div><ObjectFinder collection="workflows" _id={row.workflow} /></div>
                    </div>
                )
            )
        },
        {
            dataField: "current_step_category",
            text: 'Step / Category',
            headerFormatter: () => (
                <div><div>Category /</div><div>Disposition</div></div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{row.current_step_name}</div>
                        <div>{row.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={row.workflow_step_category} /> : 'No Category'}</div>
                    </div>
                )
            )
        },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 140},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[

                                { 
                                    icon: 'fas fa-link',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/matters/${row.id}`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    return (

        <>

        <Helmet>
            <title>Matters Overdue</title>
            <meta name="description" content="Matters Overdue" />
        </Helmet>

        <HeaderNavigation 
            title="Matters Overdue"
            description="Newest To Oldest"
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(MattersAll);
