/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { load } from 'store/functions/load/load';
import { setViewingUser } from 'store/functions/auth/auth';
import { setDeviceListener } from 'store/functions/device/device'
import { initWorker } from 'utils/pdf/controller'
import { getLoginUrl } from 'utils/helpers'
import socket from 'socket.io-client';
import { setSocket } from 'store/functions/socket'

import * as actionTypes from 'store/actions';
import store from 'store';

const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);
    // close all user tabs on logout

    // listen for changes in screen size, set device, etc
    setDeviceListener()

    const tryLoad = async (tries = 0) => {
        if(window.location.href.includes('/errors/')) return resolve(true);

        //run all promises that need to fire before the app loads here
        const values = await Promise.all([
            setViewingUser(),
            load(),
        ])

        const user = values[0];

        if(!user.success) {
            if(tries > 2) return resolve(false);
            return setTimeout(() => tryLoad(tries + 1), 2000);
        }

        if(!user.data) return window.location.href = getLoginUrl();
        if(!user.data.privileges.includes('VIEW.CAN_VIEW_MANAGEMENT_DASHBOARD')) return resolve('unauthorized');

        window.addEventListener('focus', async () => {
            const user = await setViewingUser(true);
            if(!user.data) window.location.href = getLoginUrl();
        })

        // joins company and user removes upon start
        const websocket = socket(values[1].data.DOMAINS.SOCKETS, {
            transports: ['websocket'],
            autoConnect: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax : 5000,
            reconnectionAttempts: Infinity
        });
        setSocket(websocket)

        websocket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
            store.dispatch({ type: actionTypes.SET_CALL_CENTER, payload: { browser_dialing: 'error' }});
        });
        
        websocket.on("connect", () => {
            
        });

        initWorker();
        return resolve(true);

    }

    tryLoad();

})

export default init