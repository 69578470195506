import { Card, CardBody, Progress, Row, Col } from "reactstrap";

const Overview = ({ total, title, overdue, completed }) => {

    const percent = completed || total ? ((completed / total) * 100 ).toFixed(2) : 100

    let className = "";
    if(percent >= 0 && percent < 19.99) className = 'danger'
    if(percent >= 20 && percent < 49.99) className = 'warning'
    if(percent >= 50 && percent < 97.99) className = 'info'
    if(percent >= 98) className = 'success'

    return (
        <Card className='my-3' style={{borderRadius: 6}}>
            <CardBody>
                <div className="progress-wrapper pt-3">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 text-${className}`}>
                                 {title}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{ percent}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={percent < 5 ? 5 : percent} color={className} />
                    <div className="mt--2">
                        {completed}/{total} 
                        {overdue ? (
                            <span className='float-right text-danger font-weight-bold'>{overdue} Overdue</span>
                        ) : ''}
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

const DashboardOverview = ({ data }) => (
    <div>
          <Row className="archk-overview">
                <Col xl={6} lg={6}>
                    <Overview 
                        title="Today's Completed Events"
                        total={data.events.total}
                        overdue={data.events.overdue}
                        completed={data.events.total - data.events.unfinished}
                    />
                </Col>
                <Col xl={6} lg={6}>
                    <Overview 
                        title="Today's Completed Tasks"
                        total={data.tasks.total}
                        overdue={data.tasks.overdue}
                        completed={data.tasks.total - data.tasks.unfinished}
                    />
                </Col>
            </Row>
           
            <Row className="archk-overview">
                <Col xl={6} lg={6}>
                    <Overview 
                        title="Read Email Threads"
                        total={data.emailThreads.total}
                        overdue={0}
                        completed={data.emailThreads.total - data.emailThreads.unread}
                    />
                </Col>
                <Col xl={6} lg={6}>
                    <Overview 
                        title="Resolved Voicemails"
                        total={data.callVoicemails.total}
                        overdue={0}
                        completed={data.callVoicemails.total - data.callVoicemails.unresolved}
                    />
                </Col>
            </Row>
    </div>
)

export default DashboardOverview