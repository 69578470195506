import { memo, useCallback, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardBody, Col, CardHeader, CardTitle, Row, Container } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import moment from 'moment';

import { Helmet } from 'react-helmet';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

const size = 10

const PipelineCol = ({}) => {
   
    const listInnerRef = useRef();
    const [doneFetching, setDoneFetching] = useState(null)
    const [matters, setMatters] = useState(null)
    const [totalDocuments, setTotalDocuments] = useState(null)
    const [sortType, setSortType] = useState('newest')

    const fetchMore = useCallback(async () => {
        if(doneFetching) return

        const params = { 
            searchString: '', 
            skip: matters.length,  
            limit:  size, 
            filter: { 
                resolved_at: 0
            } 
        }
       
        const query = await api.ai_problems.search(params)
        if(query.data) {
            let _matters = matters.concat(query.data.documents)
            if(query.data.total_documents === _matters.length) setDoneFetching(true)
            setMatters(_matters)
        }

    }, [matters, doneFetching])

    const fetchInitial = useCallback(async () => {

        const params = { 
            searchString: '', 
            skip: 0,  
            limit:  size,
            filter: { 
                resolved_at: 0
            } 
        }
       
        const query = await api.ai_problems.search(params)
        if(query.data) {
            setDoneFetching(query.data.total_documents === query.data.documents.length ? true : false)
            setMatters(query.data.documents)
            setTotalDocuments(query.data.total_documents)
        }

    }, [])

    const onScroll = useCallback(() => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollHeight < scrollTop + clientHeight + 1) {
                fetchMore()
            }
        }
    }, [fetchMore])

    useEffect(() => {
        fetchInitial()
    }, [fetchInitial])

    return (
        <>
        <Helmet>
            <title>Problems</title>
            <meta name="description" content="Problems" />
        </Helmet>

        <HeaderNavigation 
            title="Problems"
            description="Potential Detected Issues"
            leftColSize={4}
        />

        <Container fluid>
            <Row>
                <Col className="archk-pipeline-col">

                    <div 
                        className="archk-pipeline-col-wrapper border-left border-right"  
                        onScroll={onScroll} 
                        ref={listInnerRef}
                    >
                        <div className="archk-pipeline-col-inner pt-3">

                            {!matters ? (
                                <Circle />
                            ) : !matters.length ? (
                                <Card>
                                    <CardBody>
                                        <p className="mb-0 text-sm">
                                            <i className="fas fa-info-circle mr-2 text-info" /> No Matters Found
                                        </p>
                                    </CardBody>
                                </Card>
                            ) : matters.map(matter => (
                                <Card>
                                    <CardHeader>
                                        <CardTitle className="mb-0">
                                            <Row>
                                                <Col md={6} className="align-self-center">
                                                    <ObjectFinder collection="matters" _id={matter.matter} />
                                                </Col>
                                                <Col md={6} className="align-self-center text-right">
                                                    Identified On: {moment.unix(matter.created_at).format('MM/DD/YYYY h:mm A')}
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                    </CardHeader>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Problem</th>
                                                    <th>Resolved</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {matter.problems.map(p => (
                                                    <tr key={p._id}>
                                                        <td>
                                                            {p.problem}
                                                            {p.resolved_at ? (
                                                                <div className="font-weight-bold text-dark">
                                                                    Resolved At: {moment.unix(p.resolved_at).format('MM/DD/YYYY h:mm A')}
                                                                </div>
                                                            ) : ''}
                                                            
                                                        </td>
                                                        <td className="text-center">
                                                            {p.resolved_at ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}
                                                        </td>
                                                        <td>
                                                            {!p.resolved_at ? (
                                                                <button className="btn btn-sm btn-success">Resolve</button>
                                                            ) : ''}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            ))}

                            {!doneFetching ? (
                                <button onClick={fetchMore} className="btn btn-block btn-success my-3">Fetch More</button>
                            ) : ''}

                        </div>

                    </div>
                </Col>
            </Row>
        </Container>

        </>
    )
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(memo(PipelineCol));
