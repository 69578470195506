import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import ApiError from "components/markup/layout/ApiError";
import Circle from "components/markup/loading/Circle";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';
import formatText from 'utils/formatText';

const Clock = ({time, inSeconds}) => {

    const [ now, setNow ] = useState(Math.floor(new Date() / 1000));

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(Math.floor(new Date() / 1000))
        }, inSeconds ? 1000 : 60000)

        return () => {
            clearInterval(interval)
        }
    }, [inSeconds])

    return time ? formatText(now - time).secondsToTime() : '-'
}

const CommunicationLiveUsers = ({socket, selected_division}) => {

    const [ users, setUsers ] = useState(null);
    const [ err, setErr ] = useState(false)

    const onAgentStatusChange = useCallback((user) => {
        const index = users.findIndex(u => u._id === user._id);
        if(index !== -1) {
            const _users = JSON.parse(JSON.stringify(users))
            _users[index] = user;
            setUsers(_users)
        }
    }, [users])

    const onAgentCanReceiveCalls = useCallback((user) => {
        const index = users.findIndex(u => u._id === user._id);
        if(index !== -1) {
            const _users = JSON.parse(JSON.stringify(users))
            _users[index].agent_can_receive_calls = user.agent_can_receive_calls;
            setUsers(_users)
        }
    }, [users])

    const userUpdated = useCallback((user) => {
        const index = users.findIndex(u => u._id === user._id);
        if(index !== -1) {
            const _users = JSON.parse(JSON.stringify(users))
            _users[index].agent_is_in_queue = user.agent_is_in_queue;
            setUsers(_users)
        }
    }, [users])

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async () => {
        const data = await api._supervisor.communication.users(selected_division._id)
        if(!data.data) return setErr(data.message) 
        setUsers(data.data)
    }, [selected_division._id])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {

        socket.on('CALL_CENTER.AGENT_CALL_STATUS_CHANGED', onAgentStatusChange)
        socket.on('AGENT_CAN_RECEIVE_CALLS', onAgentCanReceiveCalls)
        socket.on('USERS.UPDATED', userUpdated)

        return () => {
            socket.off('CALL_CENTER.AGENT_CALL_STATUS_CHANGED', onAgentStatusChange)
            socket.off('AGENT_CAN_RECEIVE_CALLS', onAgentCanReceiveCalls)
            socket.off('USERS.UPDATED', userUpdated)
        }

    }, [socket, onAgentStatusChange, onAgentCanReceiveCalls, userUpdated])

    if(err) return <ApiError err={err} className="mx-4 py-3" />
    if(!users) return <Circle />

    const usersOnCall       = users.filter(u => u.agent_current_call);
    const usersInQueue      = users.filter(u => !u.agent_current_call && u.agent_can_receive_calls && u.agent_is_in_queue);
    const usersAvailable    = users.filter(u => !u.agent_current_call && u.agent_can_receive_calls && !u.agent_is_in_queue);
    const usersUnavailable  = users.filter(u => !u.agent_can_receive_calls);

    return (

        <div>
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <CardHeader>
                    <CardTitle className="text-sm mb-0">Users On Call / Direct Dial Incoming</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            {usersOnCall.length ? (
                                <tr>
                                    <th>Name</th>
                                </tr>
                            ) : <></>}
                            
                        </thead>

                        <tbody>
                            {usersOnCall.length ? usersOnCall.map(u => (
                                <tr key={u._id}>
                                    <td>
                                        <Row>
                                            <Col md={8} className="pl-0">
                                                <div>
                                                    <b style={{width: 75, display: 'inline-block'}}>User:</b>{' '}
                                                    {u.display_name}
                                                </div>
                                                <div>
                                                    <b style={{width: 75, display: 'inline-block'}}>Contact:</b>{' '}
                                                    {u.contact ? <ObjectFinder collection="contacts" _id={u.contact} /> : 'Contact Not Found'}
                                                    
                                                </div>
                                                <div>
                                                    <b style={{width: 75, display: 'inline-block'}}>Matter:</b>{' '}
                                                    {u.matter ? <ObjectFinder collection="matters" _id={u.matter} /> : '-' }
                                                </div>
                                            </Col>

                                            <Col md={4} className="text-right pr-0">
                                                <i className="fas fa-clock mr-2 text-success" /> <Clock time={u.agent_last_call_started} inSeconds={true} />
                                                <div>From: {formatText(u.from).phone()}</div>
                                                <div>To: {formatText(u.to).phone()}</div>
                                            </Col>
                                        </Row>

                                        {/* <hr className="my-2" /> */}


                                        <Row>
                                            <Col md={8} className="pl-0 align-self-center">
                                                <div>
                                                    <b style={{width: 75, display: 'inline-block'}}>Department</b>{' '}
                                                    {u.division_department ? <ObjectFinder collection="division_departments" _id={u.division_department} /> : ''}
                                                </div>
                                                <div>
                                                    <b style={{width: 75, display: 'inline-block'}}>Matter Step</b>{' '}
                                                    {u.workflow_step ? <ObjectFinder collection="workflow_steps" _id={u.workflow_step} /> : ''}
                                                </div>
                                            </Col>
                                            <Col md={4} className="pr-0 text-right align-self-center">
                                                {u.direction === 'inbound' ? (
                                                    <div className="text-success"><i className="fas fa-headset" /> <i className="fas fa-arrow-down" /> Inbound</div>
                                                ) : (
                                                    <div className="text-info"><i className="fas fa-headset" /> <i className="fas fa-arrow-up" /> Outbound</div>
                                                )}

                                                {u.lead_source ? <ObjectFinder collection="lead_sources" _id={u.lead_source} /> : ''}

                                            </Col>
                                        </Row>
                                        
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td><i className="fas fa-info-circle text-warning mr-2" /> There are no users currently on call.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter className="bg-se">
                    <p className="text-sm mb-0">{usersOnCall.length} Total</p>
                </CardFooter>

            </Card>

            <Card className="card-color card-primary table-fixed table-no-stripes z-depth-">
                <CardHeader>
                    <CardTitle className="text-sm mb-0">Users In Queue</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            {usersInQueue.length ? (
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right">Last Call Ended</th>
                                </tr>
                            ) : <></>}
                           
                        </thead>

                        <tbody>
                            {usersInQueue.length ? usersInQueue.map(u => (
                               <tr key={u._id}>
                                    <td><i className="fas fa-headset mr-2 text-success" /> {u.display_name}</td>
                                    <td className="text-right"><Clock time={u.agent_last_call_ended} /></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td><i className="fas fa-info-circle text-warning mr-2" /> There are no users awaiting queue calls.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter className="bg-se">
                    <p className="text-sm mb-0">{usersInQueue.length} Total</p>
                </CardFooter>

            </Card>
            
            <Card className="card-color card-primary table-fixed table-no-stripes z-depth-">
                <CardHeader>
                    <CardTitle className="text-sm mb-0">Users With Phone Connection</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            {usersAvailable.length ? (
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right">Last Call Ended</th>
                                </tr>
                            ) : <></>}
                           
                        </thead>

                        <tbody>
                            {usersAvailable.length ? usersAvailable.map(u => (
                                <tr key={u._id}>
                                    <td><i className="fas fa-check text-info mr-2" /> {u.display_name}</td>
                                    <td className="text-right"><Clock time={u.agent_last_call_ended} /></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td><i className="fas fa-info-circle text-info mr-2" /> There are no available users currently.</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter className="bg-se">
                    <p className="text-sm mb-0">{usersAvailable.length} Total</p>
                </CardFooter>

            </Card>
            
            <Card className="card-color card-primary table-fixed table-no-stripes z-depth-">

                <CardHeader>
                    <CardTitle className="text-sm mb-0">Unavailable Users</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            {usersUnavailable.length ? (
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right">Last Call Ended</th>
                                </tr>
                            ) : <></>}
                            
                        </thead>

                        <tbody>
                            {usersUnavailable.length ? usersUnavailable.map(u => (
                                <tr key={u._id}>
                                    <td><i className="fas fa-times mr-2 text-danger" /> {u.display_name}</td>
                                    <td className="text-right"><Clock time={u.agent_last_call_ended} /></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td><i className="fas fa-info-circle text-info mr-2" /> There are no unavailable users currently.</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter className="bg-se">
                    <p className="text-sm mb-0">{usersUnavailable.length} Total</p>
                </CardFooter>

            </Card>
            
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(CommunicationLiveUsers);
