import { Link, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink } from "reactstrap";

const CommunicationNavigation = () => {
    const location = useLocation();

    let tab = 'live';

    if(location.pathname.includes('user')) {
        tab = 'agent'
    } else if(location.pathname.includes('unreturned')) {
        tab = 'unreturned'
    } else if(location.pathname.includes('voicemail')) {
        tab = 'voicemail'
    } else if(location.pathname.includes('email')) {
        tab = 'email'
    } else if(location.pathname.includes('text')) {
        tab = 'text'
    }

    return (
        <Nav
            className="nav-fill flex-column flex-sm-row my-4"
            id="tabs-text"
            pills
            role="tablist"
        >
            <NavItem>
                <NavLink
                    aria-selected={tab === 'live'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'live' ? 'active' : ''}`}
                    to="/communication"
                    tag={Link}
                >
                Queues
                </NavLink>
            </NavItem>
    
            <NavItem>
                <NavLink
                    aria-selected={tab === 'agent'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'agent' ? 'active' : ''}`}
                    to="/communication/users"
                    tag={Link}
                >
                Agents
                </NavLink>
            </NavItem>
    
            <NavItem>
                <NavLink
                    aria-selected={tab === 'unreturned'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'unreturned' ? 'active' : ''}`}
                    to="/communication/unreturned"
                    tag={Link}
                >
                Unreturned
                </NavLink>
            </NavItem>
          
            <NavItem>
                <NavLink
                    aria-selected={tab === 'voicemail'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'voicemail' ? 'active' : ''}`}
                    to="/communication/voicemail"
                    tag={Link}
                >
                Voicemail
                </NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink
                    aria-selected={tab === 'email'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'email' ? 'active' : ''}`}
                    to="/communication/email"
                    tag={Link}
                >
                Emails
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    aria-selected={tab === 'text'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'text' ? 'active' : ''}`}
                    to="/communication/text"
                    tag={Link}
                >
                Texts
                </NavLink>
            </NavItem> */}
        </Nav>
    
    )
}

export default CommunicationNavigation