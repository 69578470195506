import * as actionTypes from '../../actions';

const initialState = {
    browser_dialing: 'not_setup',
    incoming_call: {
        // type: user / contact
        // name
        // phone
        // email
    },
    current_call: {
        
    },
    transfer_call: {
        
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CALL_CENTER:
            return {
                ...state,
                ...action.payload,
            }

        default: return state;

    }
}

export default reducer;
