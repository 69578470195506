import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from "components/markup/loading/Circle";

import api from 'api';

import { formatCurrency } from 'utils/currency';

import image from './image.svg';

import moment from 'moment';

const SupervisorRevenueProjections = ({selected_division, match}) => {
    const [ loading, setLoading ] = useState(false);

    const [date, setDate] = useState(parseInt(moment().add(1, 'days').startOf('day').format('X')))
    const [data, setData] = useState()
    const [department, setDepartment] = useState(null)

    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async () => {

        setLoading(true)
        const data = await api._supervisor.revenueProjections(selected_division._id, { date })
        setLoading(false)
        if(!data.data) return setErr(data.message) 

        setData(data.data);

    }, [selected_division._id, date])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    return (

        <>
            <Helmet>
                <title>Revenue Projections</title>
                <meta name="description" content="Revenue Projections" />
            </Helmet>

            <HeaderNavigation 
                title="Revenue Projections"
                description="Division Breakdown"
                leftColSize={4}
            />

            <Container fluid>

                {loading ? (
                    <Circle />
                ) : !data ? (
                    <div className="text-center py-6">
                        <img style={{width: 200}} src={image} alt="departments" />
                        <p className="font-weight-bold text-uppercase mt-5">Select A Department Above To View Metrics</p>
                    </div>
                ) : (
                    <Row>
                        <Col lg={12}>
                            <Card >

                                <CardHeader>
                                    <CardTitle className="mb-0">Total Values</CardTitle>
                                </CardHeader>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Total Plan Value</th>
                                                <th className="text-center">Cycle Amount</th>
                                                <th className="text-center">Amount Succeeded</th>
                                                <th className="text-right">Amount Failed</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>{formatCurrency(data.breakdown.recurring_balance)}</td>
                                                <td className="text-center">{formatCurrency(data.breakdown.amount)}</td>
                                                <td className="text-center">{formatCurrency(data.breakdown.amount_succeeded)}</td>
                                                <td className="text-right">{formatCurrency(data.breakdown.amount_failed)}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card >

                                <CardHeader>
                                    <CardTitle className="mb-0">Payment Projections</CardTitle>
                                </CardHeader>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Time Frame</th>
                                                <th className="text-right">Expected Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>Tomorrow</td>
                                                <td className="text-right">{formatCurrency(data.projections.tomorrow)}</td>
                                            </tr>
                                            <tr>
                                                <td>7 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_7)}</td>
                                            </tr>
                                            <tr>
                                                <td>14 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_14)}</td>
                                            </tr>
                                            <tr>
                                                <td>30 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_30)}</td>
                                            </tr>
                                            <tr>
                                                <td>60 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_60)}</td>
                                            </tr>
                                            <tr>
                                                <td>90 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_90)}</td>
                                            </tr>
                                            <tr>
                                                <td>180 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_180)}</td>
                                            </tr>
                                            <tr>
                                                <td>365 Days</td>
                                                <td className="text-right">{formatCurrency(data.projections.days_365)}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card >

                                <CardHeader>
                                    <CardTitle className="mb-0">Payment Breakdown</CardTitle>
                                </CardHeader>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th className="text-right">Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>Weekly Payment Plans</td>
                                                <td className="text-right">{data.breakdown.weekly}</td>
                                            </tr>
                                            <tr>
                                                <td>Weekly Payment Plans</td>
                                                <td className="text-right">{data.breakdown.biweekly}</td>
                                            </tr>
                                            <tr>
                                                <td>1st Of Month Payment Plans</td>
                                                <td className="text-right">{data.breakdown.first_of_month}</td>
                                            </tr>
                                            <tr>
                                                <td>15th Of Month Payment Plans</td>
                                                <td className="text-right">{data.breakdown.fifteenth_of_month}</td>
                                            </tr>
                                            <tr>
                                                <td>1st & 15th Of Month Payment Plans</td>
                                                <td className="text-right">{data.breakdown.first_and_fifteenth_of_month}</td>
                                            </tr>
                                            <tr>
                                                <td>Monthly Plans</td>
                                                <td className="text-right">{data.breakdown.once_a_month}</td>
                                            </tr>
                                            <tr>
                                                <td>Twice Monthly Plans</td>
                                                <td className="text-right">{data.breakdown.twice_a_month}</td>
                                            </tr>
                                            <tr>
                                                <td>Delinquent Plans</td>
                                                <td className="text-right">{data.breakdown.delinquent}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </Card>
                        </Col>
                    </Row>
                )}

            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SupervisorRevenueProjections);
