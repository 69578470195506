import { Link, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink } from "reactstrap";

const SupervisorSignatureNavigation = () => {
    const location = useLocation();

    let tab = 'analytics';

    if(location.pathname.includes('user')) {
        tab = 'users'
    } else if(location.pathname.includes('signed')) {
        tab = 'signed'
    } 
    return (
        <Nav
            className="nav-fill flex-column flex-sm-row my-4"
            id="tabs-text"
            pills
            role="tablist"
        >
            <NavItem>
                <NavLink
                    aria-selected={tab === 'analytics'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'analytics' ? 'active' : ''}`}
                    to="/signatures"
                    tag={Link}
                >
                Analytics
                </NavLink>
            </NavItem>
    
            <NavItem>
                <NavLink
                    aria-selected={tab === 'signed'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'signed' ? 'active' : ''}`}
                    to="/signatures/signed"
                    tag={Link}
                >
                Signed
                </NavLink>
            </NavItem>
{/*     
            <NavItem>
                <NavLink
                    aria-selected={tab === 'users'}
                    className={`mb-sm-3 mb-md-0 ${tab === 'users' ? 'active' : ''}`}
                    to="/signatures/users"
                    tag={Link}
                >
                    Users
                </NavLink>
            </NavItem>
           */}
       
        </Nav>
    
    )
}

export default SupervisorSignatureNavigation