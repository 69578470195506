export const TOGGLE_SYSTEM_ALERT          = 'TOGGLE_SYSTEM_ALERT';
export const TOGGLE_SYSTEM_LOADER         = 'TOGGLE_SYSTEM_LOADER';
export const TOGGLE_STANDARD_LOADER       = 'TOGGLE_STANDARD_LOADER';
export const TOGGLE_SYSTEM_ALERT_BS       = 'TOGGLE_SYSTEM_ALERT_BS'
export const SET_LOGIN_REDIRECT           = 'SET_LOGIN_REDIRECT'

export const SET_CONFIG                   = 'SET_CONFIG';
export const SET_LOADED                   = 'SET_LOADED';
export const SET_VIEWING_USER             = 'SET_VIEWING_USER';
export const SET_COMPANY                  = 'SET_COMPANY';
export const SET_TUTORIAL                 = 'SET_TUTORIAL';
export const SET_ARCHK_ALERTS             = 'SET_ARCHK_ALERTS';

export const SET_NAVBAR_STATE             = 'SET_NAVBAR_STATE';

export const SET_DEVICE                   = 'SET_DEVICE';
export const SET_USERS                    = 'SET_USERS';
export const SET_APPOINTMENT_TEMPLATES    = 'SET_APPOINTMENT_TEMPLATES';
export const SET_CONTACT_TAGS             = 'SET_CONTACT_TAGS';
export const SET_AUTOMATIONS              = 'SET_AUTOMATIONS';
export const SET_MATTER_DOCUMENTS         = 'SET_MATTER_DOCUMENTS';
export const SET_SIGNING_TEMPLATES        = 'SET_SIGNING_TEMPLATES';
export const SET_EMAIL_TEMPLATES          = 'SET_EMAIL_TEMPLATES';
export const SET_TEXT_TEMPLATES           = 'SET_TEXT_TEMPLATES';
export const SET_FORMS                    = 'SET_FORMS';
export const SET_OBJECTS                  = 'SET_OBJECTS';
export const SET_MULTIPLE_OBJECTS         = 'SET_MULTIPLE_OBJECTS';
export const SET_DIVISIONS                = 'SET_DIVISIONS';
export const SET_STATE                    = 'SET_STATE';
export const REMOVE_EMAIL_EDITOR          = 'REMOVE_EMAIL_EDITOR';
export const ADD_EMAIL_EDITOR             = 'ADD_EMAIL_EDITOR';
export const SET_SOCKET                   = 'SET_SOCKET';
export const SET_SELECTED_MATTER          = 'SET_SELECTED_MATTER';
export const SET_DOCUMENT_CENTER          = 'SET_DOCUMENT_CENTER';

export const SET_CALL_CENTER          = 'SET_CALL_CENTER';
export const SET_SELECTED_CONTACT          = 'SET_SELECTED_CONTACT';
