import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, Container } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Navigation from '../Navigation';

import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';
import formatText from 'utils/formatText';

import api from 'api';

import { toast } from 'react-toastify';

const MattersAll = ({selected_division, DOMAINS}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)


    const setAsReturned = useCallback(async (call_id) => {
        const updated = await api.calls.setReturned(call_id)
        if(!updated.success) return toast.error(`Something went wrong marking this call as returned. Please try again.`)

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 300)

    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.filter.returned = false;
        params.filter.direction = 'inbound';
        params.filter.division = selected_division._id;
        params.filter.duration = { $gt: 2 };

        // params.filter.user = viewing_user._id

        const query = await api._supervisor.communication.calls(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "matter",
            text: 'Matter & Contact',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                return (
                    <div>
                        {row.matter ? (
                        <div>
                            <A href={`${DOMAINS.APP}/matters/${row.matter}`} className="text-dark font-weight-bold">
                                <i className="fas fa-gavel mr-2 text-info" /> <ObjectFinder collection="matters" _id={row.matter} />
                            </A>
                        </div>
                        ) : ''}
                        <div>
                            <A href={`${DOMAINS.APP}/contacts/${row.contact}`} className="text-dark font-weight-bold">
                                <i className="fas fa-user mr-2 text-success" /> <ObjectFinder collection="contacts" _id={row.contact} />
                            </A>
                        </div>
                    </div>
                )
            },
        },
        ...(selected_division._id ? [] : [ {
            dataField: "division",
            text: 'Division',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : <ObjectFinder collection="divisions" _id={row.division} />
            )
        },]),

        {
            dataField: "direction",
            text: 'Direction & Duration',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div className="text-capitalize">
                        <div>

                            {row.direction} 
                            {row.direction === 'outbound' ? (
                                <span className={row.status === 'completed' ? 'text-success' : 'text-warning'}>
                                    <i className="fas fa-phone ml-2" /><i className="fas fa-arrow-up ml-" /> 
                                </span>
                            ) : (
                                <span className={row.status === 'completed' ? 'text-success' : 'text-warning'}>
                                    <i className="fas fa-phone ml-2 " /><i className="fas fa-arrow-down  ml-" /> 
                                </span>
                            )}
                        </div>
                        <div>{formatText(row.duration).secondsToTime()}</div>
                    </div>
                )
            )
        },
        {
            dataField: "status",
            text: 'Call Status',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <span className="text-capitalize">
                    {row.status ? row.abandon_type === 'short' ? 'Short Abandon' : row.status.replace ('_', ' ') : row.status}
                </span>
            )
        },

        {
            dataField: "start",
            text: 'Start',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{moment.unix(row.start).format('MM/DD/YYYY')}</div>
                        <div>{moment.unix(row.start).format('h:mm A')}</div>
                    </div>
                )
            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-check',
                                    color: 'success', 
                                    onClick: () => setAsReturned(row._id)
                                },
                                {
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/calls/${row._id}`,
                                }
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} />

    return (

        <>

        <Helmet>
            <title>Call Logs</title>
            <meta name="description" content="Call Logs" />
        </Helmet>

        <HeaderNavigation 
            title="Unreturned Calls"
            description={`${selected_division.name ? selected_division.name : 'All'} Division`}
        />

        <Container fluid>

            <Navigation />
    
    
            <Card className="card-color card-primary table-fixed">
                {loading ? <Circle /> : (
                    <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Status..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
                )}
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
