import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import A from 'components/markup/links/A';

import ApiError from "components/markup/layout/ApiError";

import api from 'api';

import reactHTMLParser from 'html-react-parser';

const SupervisorMatterAlerts = ({DOMAINS, selected_division}) => {
    const [ notes, setNotes ] = useState(null)
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api._supervisor.matterAlerts(selected_division._id);
        if(data.data) return setNotes(data.data);

        return setErr(data.message)
    }, [selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    return (

        <>

        <Helmet>
            <title>Matters Alerts</title>
            <meta name="description" content="Matters Alerts" />
        </Helmet>

        <HeaderNavigation 
            title="Matters Alerts"
            description="Most Recent"
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <CardHeader>
                    <CardTitle className="mb-0">Most Recent Matter Alerts (500)</CardTitle>
                </CardHeader>

                {!notes ? (
                    <CardBody><Circle /></CardBody>
                ) : (
                    <div className="table table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Matter</th>
                                    <th>Date</th>
                                    <th>Alert</th>
                                </tr>
                            </thead>

                            <tbody>
                                {notes.length ? notes.map((note, i) => (
                                    <tr key={note._id}>

                                        <td>
                                            <A href={`${DOMAINS.APP}/matters/${note.matter.id}`}>
                                                {note.matter ? note.matter.name : '-'}
                                            </A>
                                        </td>
                                        
                                        <td>
                                            {moment.unix(note.created_at).format('MM/DD/YYYY h:mm A')}
                                        </td>

                                        <td>
                                            <A href={`${DOMAINS.APP}/matters/${note.matter.id}`}>
                                                {reactHTMLParser(note.value)}
                                            </A>
                                        </td>

                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No Alerts Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}

                
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(SupervisorMatterAlerts);
