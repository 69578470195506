import { Card, CardHeader, CardTitle } from "reactstrap";

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';

import ObjectFinder from 'components/system/Objects/Finder';

import moment from 'moment';

const DashboardOverview = ({ data }) => (
    <Card>
        <CardHeader>
            <CardTitle className='mb-0 text-ceter'>Today's Leads</CardTitle>
        </CardHeader>

        <div className='table-responsive'>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Source</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Estimated $</th>
                        <th>Conversion %</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Type</th>
                        <th className='text-right'>Created</th>
                    </tr>
                </thead>

                <tbody>
                    {data.leads.length ? data.leads.map(l => (
                        <tr key={l._id}>
                            <td>{l.display_name}</td>
                            <td><ObjectFinder collection="lead_sources" _id={l.lead_source} /></td>
                            <td>{formatText(l.phone).phone()}</td>
                            <td>{l.email}</td>
                            <td>{formatCurrency(l.estimated_value)}</td>
                            <td>~{l.likely_hood_of_converting}%</td>
                            <td>{l.state}</td>
                            <td>{l.postal_code}</td>
                            <td>{l.type}</td>
                            <td className='text-right'>{moment.unix(l.created_at).format('h:mm A')}</td>
                        </tr>
                    )) : (
                        <tr>
                            <td>There are no leads found for this day</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>

    </Card>
)

export default DashboardOverview