import keys from 'keys';

import formatObject from 'utils/formatObject';
import formatText from 'utils/formatText';

import Axios from 'axios';

import fileDownloader from 'js-file-download';

import { toast } from 'react-toastify';



export const getDownloadName = (document, contact) => {
    return formatText(formatObject(contact).name() + ' - ' + document.name ).capitalize() + '.pdf'
}

export const onDownloadDocumentResponse = async (pathname) => new Promise (async resolve => {
    try {
        const result = await Axios({
            method: 'get',
            url: keys.API_URL + `${pathname}`,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })

        if(result.headers && result.headers['content-type'].includes('application/json')) {

            try {
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                var body = JSON.parse(decodedString);
                if(body.message && body.message.length) {
                    return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                }
            } catch(e) {
                return toast.error(`Something went wrong downloading this document`)
            }

        }

        fileDownloader(result.data, result.headers.filename) 
    } catch(e) {
        return toast.error(`Something went wrong downloading this document`)
    }
})

export const onDownloadDocument = async (doc) => new Promise (async resolve => {

   try {
        const _id = doc && doc._id ? doc._id : doc

        // if document has a new icon next to it remove it from the page
        const badge = document.getElementById(`document-new-${_id}`)
        if(badge) badge.parentNode.removeChild(badge);

        const result = await Axios({
            method: 'get',
            url: keys.API_URL + `/v1/documents/${_id}/download`,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })

        if(result.headers && result.headers['content-type'].includes('application/json')) {
            resolve(false);
            try {
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                var body = JSON.parse(decodedString);
                if(body.message && body.message.length) {
                    return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                }
            } catch(e) {
                return toast.error(`Something went wrong downloading this document`)
            }
        }

        fileDownloader(result.data, result.headers.filename) 
        resolve(true);
    } catch(e) {
        resolve(false);
        return toast.error(`Something went wrong downloading this document`)
    }
})

// download all documents passed in, set a 1.5 second timeout in between to no overload the server
export const onDownloadDocuments = (documents) => {

    const download = async (index) => {
        const doc = documents[index];
        if(!doc) return;
        await onDownloadDocument(doc)
        setTimeout(() => {
            download(index + 1);
        }, 1500)
    }

    download(0)
}

export const onDownloadSignedDocument = async (doc) => {

    const _id = doc && doc._id ? doc._id : doc

    try {
        const result = await Axios({
            method: 'get',
            url: keys.API_URL + `/v1/signing/documents/${_id}/download`,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })
    
        if(result.headers && result.headers['content-type'].includes('application/json')) {
            return toast.error(`Something went wrong downloading this document`)
        }
    
        fileDownloader(result.data, result.headers.filename) 
    } catch(e) {
        return toast.error(`Something went wrong downloading this document`)
    }

}

export const needsExtension = (doc) => {
    const now = Math.floor(new Date() / 1000)
    if(doc.status !== 'completed') return false;
    if(doc.can_download_until > now) return false; 
    if(doc.finished_at > (now - (86400 * 30)) ) return false;
    return true;
}