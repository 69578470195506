import { useCallback, useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import ApiError from "components/markup/layout/ApiError";
import Circle from "components/markup/loading/Circle";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import moment from 'moment';

import { toast } from 'react-toastify'

const reducer = (state, action) => {

    switch (action.type) {
        case "full":
            state = action.payload;
            return state
        case "taken":
            state = state.filter(s => s._id !== action.payload._id)
            return state
        case "deleted":
            state = state.filter(s => s._id !== action.payload._id)
            return state

      default:
        return state;
    }
};


const CommunicationActiveQueueEntries = ({socket, selected_division}) => {

    const [queueEntries, dispatch] = useReducer(reducer, []);
    const [ err, setErr ] = useState(false)

    const onDelete = useCallback(async (queue) => {
        const deleted = await api.call_queue_entries.delete(queue._id);
        if(!deleted.success) return toast.info(`Could not delete entry at this time, please try again.`)
    }, [])

     // helper for this.downloadCSV and this.query
     const fetchData = useCallback(async () => {
        const data = await api._supervisor.communication.activeQueueEntries(selected_division._id)
        if(data.data.documents) return dispatch({ type: "full", payload: data.data.documents });
        setErr(data.message)
    }, [selected_division._id])

    const onQueueTaken = useCallback((payload) => {
        dispatch({ type: "taken", payload });
    }, [])
    const onQueueDeleted = useCallback((payload) => {
        dispatch({ type: "deleted", payload });
    }, [])
   

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {

        socket.on('CALL_CENTER.CALL_QUEUE_CREATED', fetchData)
        socket.on('CALL_CENTER.CALL_QUEUE_TAKEN', onQueueTaken)
        socket.on('CALL_CENTER.CALL_QUEUE_DELETED', onQueueDeleted)


        return () => {
            socket.off('CALL_CENTER.CALL_QUEUE_CREATED', fetchData)
            socket.off('CALL_CENTER.CALL_QUEUE_TAKEN', onQueueTaken)
            socket.off('CALL_CENTER.CALL_QUEUE_DELETED', onQueueDeleted)

        }

    }, [socket, onQueueTaken, onQueueDeleted, fetchData])

    if(err) return <ApiError err={err} className="mx-4 py-3" />
    if(!queueEntries) return <Circle />

    return (

        <div>


            <Card className="card-color card-primary table-fixed table-no-stripes z-depth-">
                <CardHeader>
                    <CardTitle className="text-sm mb-0">Active Queue Entries</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <tbody>
                            {queueEntries.length ? queueEntries.map(u => (
                              <tr key={u._id}>
                                <td>
                                    <Row>
                                        <Col md={8} className="pl-0">
                                            <div>
                                                <b style={{width: 75, display: 'inline-block'}}>Contact:</b>{' '}
                                                {u.contact ? <ObjectFinder collection="contacts" _id={u.contact} /> : 'Contact Not Found'}
                                                
                                            </div>
                                            <div>
                                                <b style={{width: 75, display: 'inline-block'}}>Matter:</b>{' '}
                                                {u.matter ? <ObjectFinder collection="matters" _id={u.matter} /> : '-' }
                                            </div>
                                            <div>
                                                <b style={{width: 75, display: 'inline-block'}}>Title:</b>{' '}
                                                {u.queue_entry_title}
                                            </div>
                                        </Col>

                                        <Col md={4} className="text-right pr-0">
                                            <div>{moment.unix(u.created_at).format('h:mm A')}</div>
                                            <div>Priority: {u.priority}</div>
                                            <div className="text-capitalize">{u.type === 'hold' ? 'Calling In' : u.type}</div>
                                        </Col>
                                    </Row> 

                                    <div className="text-right pt-2">
                                        <button onClick={() => onDelete(u)} className="btn btn-sm btn-danger">Delete Entry</button>
                                    </div>
                                    
                                </td>
                            </tr>
                            )) : (
                                <tr>
                                    <td><i className="fas fa-info-circle text-warning mr-2" /> There are no active queue entries.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter className="bg-se">
                    <p className="text-sm mb-0">Total: {queueEntries.length}</p>
                </CardFooter>

            </Card>
            
       
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(CommunicationActiveQueueEntries);
