import store from 'store';
// import io from 'sockets';
import * as actionTypes from 'store/actions';

const handleUpdate = ({type, action, events}) => {

    const socket = store.getState().socket;

    const state = store.getState();

    const eventCreated = state.config.EVENTS[events.created]
    const eventUpdated = state.config.EVENTS[events.updated]
    const eventDeleted = state.config.EVENTS[events.deleted]

    if(events.created && !eventCreated) throw new Error(`${type}: no event found upon creation. store/function/_helpers/handleUpdate`);
    if(events.updated && !eventUpdated) throw new Error(`${type}: no event found upon update. store/function/_helpers/handleUpdate`);
    if(events.deleted && !eventDeleted) throw new Error(`${type}: no event found upon deletion. store/function/_helpers/handleUpdate`);

    if(!actionTypes[action]) throw new Error(`${type}: action not found: ${action}`);


    if(eventCreated) {
        socket.on(eventCreated, async(obj) => {
            let data = JSON.parse(JSON.stringify(store.getState()[type]));
            data.push(obj);
            store.dispatch({type: actionTypes[action], payload: data});
        })
    }
    
    if(eventUpdated) {
        socket.on(eventUpdated, async(obj) => {
            let data = JSON.parse(JSON.stringify(store.getState()[type]));
            const foundIndex = data.findIndex(d => d._id === obj._id);
            if(foundIndex !== -1) {
                data[foundIndex] = obj;
                store.dispatch({type: actionTypes[action], payload: data});
            }
        })
    }
    
    if(eventDeleted) {
        socket.on(eventDeleted, async(obj) => {
            let data = JSON.parse(JSON.stringify(store.getState()[type]));
            data = data.filter(d => d._id !== obj._id)
            store.dispatch({type: actionTypes[action], payload: data});
        })
    }
    
}

export default handleUpdate;
