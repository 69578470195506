import keys from 'keys';
import Axios from 'axios';

let logs = [];

const store = (data, start, end) => {
    let obj = {
        start,
        end,
        duration: end - start,
        request: {},
        response: {},
    }

    if(data.status === 200 || data.status === 201 || data.status === 202) {

        obj.request.url = data.config.url
        obj.request.method = data.config.method
        obj.request.data = data.config.data
        obj.response.data = data.data
        obj.response.status = data.status
        obj.response.statusText = data.statusText

    } else {
        if (data.response) {

            obj.request.url = data.config.url
            obj.request.method = data.config.method
            obj.request.data = data.config.data
            obj.response.data = data.data
            obj.response.status = data.status
            obj.response.statusText = data.statusText

        } else if (data.request) {
            const err = data.toJSON();

            obj.request.url = err.config.url
            obj.request.method = err.config.method
            obj.request.data = err.config.data
            obj.response.data = 'No Response Received From Server'
        } else {
            obj.request.url = 'Internal JS Error, Did Not Make It To Request'
        }
    }

    logs.unshift(obj)
}

const getLogs = () => {
    return logs
}
const send = async () => new Promise (async resolve => {
    try {
        const result = await Axios({
            method: 'POST',
            url: keys.API_URL + '/v1/logs_network_users',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
            data: { logs }
        })
        return resolve(result.data && result.data.data ? result.data.data._id : null)
    } catch(e) {
        return resolve(null)
    }
})

const obj = { store, getLogs, send }

export default obj;