import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Card, Container, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import Navigation from '../Navigation'

import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'
import Circle from 'components/markup/loading/Circle'
import formatText from 'utils/formatText'

import api from 'api';

import { toast } from "react-toastify";


const Transcription = ({text}) => {

    const [hover, setHover] = useState(false)

    return (
        <div
            onMouseOver={() => setHover(true)} 
            onMouseOut={() => setHover(false)} 
            style={{position: 'relative'}}
        >
            <div>{text ? text.length > 50 ? text.slice(0, 50) + '...' : text : ''}</div>
            {hover ? (
                <div   
                    className="bg-white z-depth-1 border p-3"
                    style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 300,

                        borderBottomLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                >
                    {text}
                </div>
            ) : ''}
        </div>
    )

}

const Icons = ({row}) => {

    const [canMarkResolved, setCanMarkResolved] = useState(!row.resolved_at)

    const onMarkResolved = useCallback(async () => {
        setCanMarkResolved(false)

        const updated = await api.call_voicemails.resolve(row._id)
        if(!updated.success) {
            setCanMarkResolved(true)
            toast.error(`Something went wrong marking this voicemail as resolved, please try again.`)
        }
    }, [row._id])


    return (
        <TableIcons
            icons={[

                {
                    icon: 'fas fa-thumbs-up',
                    tooltip: 'Mark Resolved',
                    color: `success ${canMarkResolved ? '' :  'd-none'}`, 
                    onClick: onMarkResolved
                },
                {
                    icon: 'fas fa-download',
                    tooltip: 'Download',
                    color: 'warning', 
                    wrapper: A, 
                    href: row.recording_url + '.mp3?Download=true',
                },
                {
                    icon: 'fa-solid fa-headset',
                    tooltip: 'Listen',
                    color: 'info', 
                    wrapper: A, 
                    href: row.recording_url,
                }
            ]}
        />
    )
}

const MattersAll = ({DOMAINS, selected_division}) => {
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)
    const [ filter, setFilter ] = useState('unresolved')
    const [ loading, setLoading ] = useState(false)

    const onSetFilter = useCallback((_filter) => {
        setLoading(true)
        setFilter(_filter)

        setTimeout(() => {
            setLoading(false)
        }, 250)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.filter.division = selected_division._id
        if(filter === 'unresolved') params.filter.resolved_at = 0;
        if(filter === 'resolved') params.filter.resolved_at = { $gt: 0 };

        const query = await api._supervisor.communication.voicemail(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    function MouseOver(event) {
        event.target.style.background = 'red';
      }
      function MouseOut(event){
        event.target.style.background="";
      }

    const columns = [
        {
            dataField: "matter",
            text: 'Matter & Contact',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                return (
                    <div>
                        {row.matter ? (
                        <div>
                            <A href={`${DOMAINS.APP}/matters/${row.matter}`} className="text-dark font-weight-bold">
                                <i className="fas fa-gavel mr-2 text-info" /> <ObjectFinder collection="matters" _id={row.matter} />
                            </A>
                        </div>
                        ) : ''}
                        <div>
                            <A href={`${DOMAINS.APP}/contacts/${row.contact}`} className="text-dark font-weight-bold">
                                <i className="fas fa-user mr-2 text-success" /> <ObjectFinder collection="contacts" _id={row.contact} />
                            </A>
                        </div>
                    </div>
                )
            },
        },
        {
            dataField: "assigned_to",
            text: 'Assigned To',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.assigned_to ? row.assigned_to.map(a => <div><ObjectFinder collection="users" _id={a} /></div> ) : '-'
        },
        {
            dataField: "transcription_text",
            text: 'Transcription',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <Transcription text={row.transcription_text} />
            )
        },
       
        
        {
            dataField: "created_at",
            text: 'Date',
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-right">
                    <div>{moment.unix(row.created_at).format('MM/DD/YYYY')}</div>
                    <div>{moment.unix(row.created_at).format('h:mm A')} - {formatText(row.recording_duration).secondsToTime()}</div>
                </div>
            )
        },
        {
            dataField: "resolved_at",
            text: 'Resolved',
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-center">
                    {/* {row.resolved_at ? (
                        <Badge style={{width: 80}} color="info"> Yes </Badge>
                    ) : (
                        <Badge style={{width: 80}} color="warning">No</Badge>
                    )} */}
                    {row.resolved_at ? (
                        <span><i className="fas fa-thumbs-up text-success" />  Yes </span>
                    ) : (
                        <span><i className="fas fa-times text-danger" />  No </span>

                    )}
                </div>
            )
        },
        {
            dataField: "call_queue",
            text: 'Assigned From',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.call_queue ? <ObjectFinder collection="call_queues" _id={row.call_queue} /> : 'Direct Voicemail'
            )
        },

        {
            dataField: "_id",
            // headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <Icons row={row} />
                    </div>
                )
            }
        },

    ]

    if(err) return <ApiError err={err} />

    return (

        <>

        <Helmet>
            <title>Voicemails</title>
            <meta name="description" content="Voicemails" />
        </Helmet>

        <HeaderNavigation 
            title="Voicemail"
            description={`${selected_division.name ? selected_division.name : 'All'} Voicemails`}
            actionComponent={(
                <UncontrolledDropdown>
                        <DropdownToggle color={filter === 'unresolved' ? 'warning' : filter === 'resolved' ? 'success' : 'info'}>
                            <span className="text-capitalize" style={{width: 100, display: 'inline-block'}}>
                                {filter ? filter : 'All Voicemails'}
                            </span>{' '}
                            <i className="fas fa-hand-pointer" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem  onClick={e => onSetFilter('unresolved')}>Unresolved</DropdownItem>
                            <DropdownItem  onClick={e => onSetFilter('resolved')}>Resolved</DropdownItem>
                            <DropdownItem  onClick={e => onSetFilter('')}>All Voicemails</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
            )}
        />

        <Container fluid>

            <Navigation />
    
            <Card className="card-color card-primary table-fixed">
                {loading ? <Circle /> : (
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search Status..."
                        hideSearch={true}
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
