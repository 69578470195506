/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    selected_division: {},
    on_call: {},
    email_editors: [],
    selected_matter: {}
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_SELECTED_MATTER:

            return {
                ...state,
                selected_matter: action.payload
            }
        
        default:
            return state;

    }

}

export default reducer;
