import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import A from 'components/markup/links/A'

import { Card, Container, Row, Col, Badge } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";

import api from 'api';

import DatePicker from 'react-datepicker'

import SearchCollections from "components/system/Search/Collections";
import ReactSelect from 'components/functional/inputs/ReactSelect';
import formatText from 'utils/formatText'

import { toast } from 'react-toastify'

const SupervisorPhoneCalls = ({DOMAINS, selected_division}) => {


    const [ category, setCategory ] = useState('all');
    const [ user, setUser ] = useState(null);
    const [ direction, setDirection ] = useState('all');
    const [ contact, setContact ] = useState('');
    const [ status, setStatus ] = useState('all');
    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    const onSetStart = useCallback((s) => {
        const _start = parseInt(moment(s).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newEnd = moment(s).endOf('month').toDate();
            setEnd(newEnd)
        }
        setStart(s)
    }, [end])
    
    const onSetEnd = useCallback((e) => {
        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(e).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newStart = moment(e).startOf('month').toDate();
            setStart(newStart)
        }
        setEnd(e)
    }, [start])

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.sort = { created_at: -1 }

        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_start > _end) return toast.info(`Search start date cannot be greater than end date.`)
        if(_end > (_start + ( 86400 * 32 ))) return toast.info(`Search range must span no more than 1 month`)

        params.filter.start = { $gte: _start, $lt: _end }

        if(user) params.filter.user = user;
        if(direction !== 'all') params.filter.direction = direction;
        if(status !== 'all') params.filter.status = status;
        if(contact) params.filter.contact = contact;
        if(category !== 'all') params.filter.ai_category = category;

        const query = await api._supervisor.phoneCalls(selected_division._id, { ...params, isCSV })

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents })
        setErr(query.message)
    }), [sizePerPage, start, end, user, direction, status, contact, category, selected_division._id])

    useEffect(() => {
        query()
        // eslint-disable-next-line
    }, [start, end, direction, status, contact, user, category])

    const columns = [
        {
            dataField: "contact",
            text: 'Contact',
            headerStyle: { width: 200 },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <A href={`/calls/${row._id}`} className="text-dark font-weight-bold">
                            <ObjectFinder collection="contacts" _id={row.contact} />
                        </A>
                        
                    </div>
                )
            },
        },
        {
            dataField: "call_legs",
            text: 'Users',
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';

                let unique = [];
                row.call_legs.forEach(l => {
                    if(l.user && !unique.some(ll => ll.user === l.user)) unique.push(l)
                })


                return unique.map((leg, i) => (
                    <div key={i}><ObjectFinder collection="users" _id={leg.user} /></div>
                ))
            }
        },
        {
            dataField: "ai_rating",
            text: 'Rating',
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';
                if(!row.ai_rating) return '-'

                return <Badge style={{width: 40}} color={row.ai_rating >= 8 ? 'success' : row.ai_rating >= 5 ? 'info' : 'danger'} className="">{row.ai_rating}</Badge>

            }
        },
        {
            dataField: "ai_category",
            text: 'Category',
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';
                if(!row.ai_category) return '-'

                return <span className="text-uppercase">{row.ai_category.replace('_', " ")}</span>

            }
        },
        {
            dataField: "start",
            text: 'Time',
            headerStyle: { width: 155 },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{moment.unix(row.start).format('MM/DD/YYYY')}</div>
                        <div>{moment.unix(row.start).format('h:mm A')}</div>
                    </div>
                )
            )
        },
        {
            dataField: "duration",
            text: 'Duration',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : formatText(row.duration).secondsToTime()
            )
        },
        {
            dataField: "direction",
            text: 'Direction',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    row.direction
                )
            )
        },
        {
            dataField: "status",
            text: 'Status',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <span className="text-capitalize">{row.status ? row.abandon_type === 'short' ? 'Short Abandon' : row.status.replace('_', ' ') : ''}</span>
                )
            )
        },
        {
            dataField: "call_queue",
            text: 'Call Queue',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.call_queue ? <ObjectFinder collection="call_queues" _id={row.call_queue} /> : '-'

            )
        },
        
        {
            dataField: "from",
            text: 'From #',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : formatText(row.from).phone()
            )
        },
        {
            dataField: "to",
            text: 'To #',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : formatText(row.to).phone()
            )
        },
        {
            dataField: "call_phone_number",
            text: 'Internal #',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : <ObjectFinder collection="call_phone_numbers" _id={row.call_phone_number} />

            )
        },
        { 
            dataField: "recording_url",
            text: 'Recording',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.recording_url ? <A className="btn btn-info btn-sm" href={row.recording_url}>Listen</A> : '-'
            )
        },
       
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 140},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-eye',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/calls/${row._id}`,
                                    tooltip: 'View Details'
                                },
                                { 
                                    icon: 'fas fa-link',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/contacts/${row.contact}`,
                                    tooltip: 'Open Matter'
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Call Logs'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            // description="All Calls"
            leftColSize={3}
            actionComponent={(
               <div></div>
            )}
        />

        <Container fluid className="mb-3">
            <Row>
                <Col md={4}>
                    <SearchCollections
                        collection={'users'}
                        title={<span>User</span>}
                        placeholder="..."
                        value={user}
                        onChange={(obj) => setUser(obj.value)}
                        isClearable={true}
                        filter={{
                            divisions: { $in: [selected_division._id] },
                        }}
                    /> 
                </Col>
                
            
                <Col md={4}>
                    <p className="text-sm mb-0">Select Start Date</p>
                    <DatePicker 
                        selected={start} 
                        onChange={(date) => onSetStart(date)} 
                    />
                </Col>
                <Col md={4}>
                    <p className="text-sm mb-0">Select End Date</p>
                    <DatePicker 
                        selected={end} 
                        onChange={(date) => onSetEnd(date)} 
                    />
                </Col>
              
                <Col md={4} className="mt--1">
                    <ReactSelect
                        title={"Direction"}
                        formGroup={true}
                        placeholder=""
                        onChange={(obj) => setDirection(obj.value)}
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'inbound', label: 'Inbound' },
                            { value: 'outbound', label: 'Outbound' },
                        ]}
                        value={direction}
                    />    
                </Col>
                <Col md={4} className="mt--1">
                    <ReactSelect
                        title={"Category"}
                        formGroup={true}
                        placeholder=""
                        onChange={(obj) => setCategory(obj.value)}
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'customer_support', label: 'Customer Support' },
                            { value: 'sales', label: 'Sales' },
                            { value: 'billing', label: 'Billing' },
                            { value: 'technical_support', label: 'Technical Support' },
                            { value: 'complaints', label: 'Complaints' },
                            { value: 'feedback', label: 'Feedback' },
                            { value: 'appointments', label: 'Appointments' },
                            { value: 'vendor_relations', label: 'Vendor Relations' },
                            { value: 'marketing', label: 'Marketing' },
                            { value: 'other', label: 'Other' },
                        ]}
                        value={category}
                    />    
                </Col>
                <Col md={4} className="mt--1">
                    <ReactSelect
                        title={"Status"}
                        formGroup={true}
                        placeholder=""
                        onChange={(obj) => setStatus(obj.value)}
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'abandoned', label: 'Abandoned' },
                            { value: 'voicemail', label: 'Voicemail' },
                            { value: 'completed', label: 'Completed' },
                            { value: 'in_progress', label: 'In Progress' },
                            { value: 'routed_out', label: 'Routed Out' },
                        ]}
                        value={status}
                    />    
                </Col>
                <Col md={12} className="mt--1">
                    <SearchCollections
                        collection={'contacts'}
                        showEnhanced="contacts"
                        title={<span>Contact</span>}
                        placeholder="..."
                        value={contact}
                        onChange={(obj) => setContact(obj.value)}
                        isClearable={true}
                        filter={{

                        }}
                    /> 
                </Col>
            
            </Row>
        </Container>

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(SupervisorPhoneCalls);
