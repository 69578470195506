import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";

const DashboardOverview = ({ data }) => (
    <Card>
        <CardHeader>
            <CardTitle className='mb-0 text-ceter'>eSignatures</CardTitle>
        </CardHeader>

        <CardBody className='text-center p-0' style={{overflow: 'hidden'}}>

            <Row>
                <Col className={`border-right py-3`}>
                    <h3>Created Today</h3>
                    <p className='mb-0 display-4'>{data.signingDocuments.total}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Signed</h3>
                    <p className='mb-0 display-4'>{data.signingDocuments.signed}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Sent</h3>
                    <p className='mb-0 display-4'>{data.signingDocuments.sent}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>In Progress</h3>
                    <p className='mb-0 display-4'>{data.signingDocuments.in_progress}</p>
                </Col>
                <Col className='border-right py-3'>
                    <h3>Voided</h3>
                    <p className='mb-0 display-4'>{data.signingDocuments.void}</p>
                </Col>
            </Row>

        </CardBody>
    </Card>
)

export default DashboardOverview