import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Badge, Card, Col, Container, Row } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import A from 'components/markup/links/A'

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import ApiError from "components/markup/layout/ApiError";
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import DatePicker from 'react-datepicker';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";

import { toast } from 'react-toastify';

const SupervisorEvents = ({selected_division, DOMAINS}) => {


    const [ eventType, setEventType ] = useState('');
    const [ user, setUser ] = useState(null);
    const [ finished, setFinished ] = useState(false);
    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));
    const [ loading, setLoading ] = useState(true);

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    const onSetStart = useCallback((s) => {
        const _start = parseInt(moment(s).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newEnd = moment(s).endOf('month').toDate();
            setEnd(newEnd)
        }
        setStart(s)
    }, [end])
    
    const onSetEnd = useCallback((e) => {
        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(e).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newStart = moment(e).startOf('month').toDate();
            setStart(newStart)
        }
        setEnd(e)
    }, [start])

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.sort = { created_at: -1 }

        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_start > _end) return toast.info(`Search start date cannot be greater than end date.`)
        if(_end > (_start + ( 86400 * 32 ))) return toast.info(`Search range must span no more than 1 month`)

        params.filter.unix_start = { $gte: _start, $lt: _end }

        if(user) params.filter.users = { $in: [user] }
        if(eventType) params.filter.event_type = eventType
        params.filter.finished_at = finished ? { $gt: 0 } : 0;

        setLoading(true)
        const query = await api._supervisor.events(selected_division._id, { ...params, isCSV })
        setLoading(false);

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents })
        setErr(query.message)
    }), [selected_division._id, sizePerPage, start, end, finished, user, eventType])

    useEffect(() => {
        query()
        // eslint-disable-next-line
    }, [start, end, finished, user, eventType])

    const columns = [
        {
            dataField: "name",
            text: 'Event',

            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <A href={`${DOMAINS.APP}/matters/${row.matter}?tab=Events`}>{row.name}</A>
                    </div>
                )
            },
        },
        {
            dataField: "users",
            text: 'Matter',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <A href={`${DOMAINS.APP}/matters/${row.matter}?tab=Events`}><ObjectFinder collection="matters" _id={row.matter} /></A>
                )
            )
        },
        {
            dataField: "users",
            text: 'Assigned To',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.users.map((t, i) => (
                    <span key={i}>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={t} /></span>
                ))
            )
        },
        {
            dataField: "outcome",
            text: 'Outcome',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.outcome ? <ObjectFinder collection="event_outcomes" _id={row.outcome} /> : ''
            )
        },
        {
            dataField: "start",
            text: 'Date',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        {moment(row.start).format('MM/DD/YYYY')}
                    </div>
                )
            )
        },
        {
            dataField: "end",
            text: 'Time',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        {moment(row.start).format('h:mm')} - {moment(row.end).format('h:mm A')}
                    </div>
                )
            )
        },
        // {
        //     dataField: "due_date",
        //     text: 'Due Date',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')
        //     )
        // },
        // {
        //     dataField: "type",
        //     text: 'Type',
        //     formatter: (cell, row) => {
        //         if(row.name === 'no data') return '-'

        //         const type = row.task_template ? row.task_template.type : 'unknown';
        //         return <span className="text-capitalize">{type ? type.replace('_', ' ') : 'Unknown'}</span>
        //     }
        // },
        // {
        //     dataField: "priority",
        //     text: 'Priority',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : row.task_template ? row.task_template.priority : row.priority
        //     )
        // },
        {
            dataField: "updated_at",
            text: 'Status',
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => (
                <div className="text-right">
                    {row.name === 'no data' ? '' : row.finished_at ? (
                        <Badge style={{width: 110}} color="success">Finished</Badge>
                    ) : (
                        <Badge style={{width: 110}} color="danger">Unfinished</Badge>
                    )}
                </div>
            )
        },
       

    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Events'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description="All Events"
            leftColSize={3}
            actionComponent={(
                <Row>
                    <Col md={2}>
                        <SearchCollections
                            collection={'event_types'}
                            title={<span>Event Type</span>}
                            placeholder="..."
                            value={eventType}
                            onChange={(obj) => setEventType(obj.value)}
                            isClearable={true}
                            filter={{
                                division: selected_division._id,
                            }}
                        /> 
                    </Col>
                    <Col md={2}>
                        <SearchCollections
                            collection={'users'}
                            title={<span>User</span>}
                            placeholder="..."
                            value={user}
                            onChange={(obj) => setUser(obj.value)}
                            isClearable={true}
                            filter={{
                                divisions: { $in: [selected_division._id] },
                            }}
                        /> 
                    </Col>
                    <Col md={2}>
                    
                        <ReactSelect
                            title={"Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setFinished(obj.value)}
                            options={[
                                { value: false, label: 'Unfinished' },
                                { value: true, label: 'Finished' },
                            ]}
                            value={finished}
                        />    
                    </Col>
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Select Start Date</p>
                        <DatePicker 
                            selected={start} 
                            onChange={(date) => onSetStart(date)} 
                        />
                    </Col>
                    <Col md={3}>
                        <p className="text-sm mb-0">Select End Date</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => onSetEnd(date)} 
                        />
                    </Col>
                
                </Row>
            )}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes position-relative">
                {loading ? (
                    <span style={{position: 'absolute', top: -7, right: '50%'}}><Circle /></span>
                ) : ''}
                <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(SupervisorEvents);
