import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

const Box = ({ title, value, color }) => {

    let style = { background: 'none' }
    if(color) style.borderBottom = 'solid 1px white'

    return (
        <Col md={3}>
            <Card className={`bg-gradient-${color}`}>
                <CardHeader style={style}>
                    <CardTitle className={`mb-0 text-center ${color ? 'text-white' : ''}`}>{title}</CardTitle>
                </CardHeader>
    
                <CardBody className='text-center py-3'>
    
                    <p className={`mb-0 display-2 ${color ? 'text-white' : ''}`}>{value}</p>
                </CardBody>
            </Card>
        </Col>
    )
}

const DashboardOverview = ({ data }) => (
    <Row>
        {data.map((d, i) => (
            <Box 
                title={d.title}
                value={d.value}
                color={d.color}
            />
        ))}       
    </Row>
)

export default DashboardOverview