import { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import NameCircle from 'components/markup/misc/NameCircle';

import { setOffCall } from 'store/functions/state'

const OnCall = ({on_call}) => {

    const [minimized, setMinimized] = useState(false)
    const [answerState, setAnswerState] = useState('Ringing')

    useEffect(() => {
        if(!on_call._id) {
            setAnswerState('Ringing')
        } else {
            setTimeout(() => {
                setAnswerState('Answered')
            }, 5000)
        }
    }, [on_call._id])

    if(!on_call._id) return <></>


    const icons = (
        <div className="archk-on-call-wapper archk-call-buttons text-centr border-bottom">
            <Row>
                <Col className='text-center'>
                    <i className="display-4 fa-solid fa-shuffle text-info" />
                </Col>
                <Col className='text-center'>
                    <i className="display-4 fa-solid fa-volume-xmark " />
                </Col>
                <Col className='text-center'>
                    <i className="display-4 fa-solid fa-pause " />
                </Col>
                <Col className='text-center'>
                    <i onClick={setOffCall} className="display-4 fa-solid fa-circle-xmark text-danger" />
                </Col>
            </Row>
        </div>
    )

    if(minimized) return (
        <div className="archk-on-call archk-on-call-minimized z-depth-2">
           
           <div className="archk-on-call-wrapper text-cente border-bottom">
                {/* <Row>
                    <Col xs={10}> */}
                        <h4 className="mb-0">John Maher</h4>
                        <i onClick={() => setMinimized(false)} className="fa-solid fa-maximize " style={{position: 'absolute', top: 10, right: 10}} />

                    {/* </Col>
                    <Col xs={2} className='text-right'>
                    </Col> */}
                {/* </Row> */}
                {/* <Row>
                    <Col xs={6}>
                        <p className='mb-0'> <small><i className="fas fa-mobile mr-2  " /> (973) 897-5091</small></p>
                    </Col>
                    <Col xs={6} className='text-right'>
                    <p className='mb-0'> <small><i className="fas fa-envelope mr-2  " /> john@architeck.io</small></p>

                    </Col>
                </Row> */}
            </div>

            {icons}
            

        </div>
    )

    return (
        <div className="archk-on-call z-depth-2">
            <div className="archk-on-call-wrapper text-centr border-bottom position-relative">

                <i onClick={() => setMinimized(true)} className="fa-solid fa-minimize " style={{position: 'absolute', top: 10, right: 10}} />

               <Row>
                    <div className="col-auto align-self-center" style={{width: 70}}>
                        <NameCircle className={answerState === 'Answered' ? 'Answered' : ''} width={60} contact={{given_name: 'John', family_name: 'Maher'}} style={{marginRight: 5}} />{' '}
                        {/* <div className='text-center'><small>Ringing</small></div> */}
                        <div className='text-center'><small>{answerState}</small></div>
                    </div>

                    <Col className="align-self-center">
                        <div className='pl-4'>
                        <h4 className="mb--0 text-dark display-inline-block">John Maher</h4>
                        <p className="text-sm mb--2 text-muted"><i className="fas fa-mobile mr-2  text-muted" /> (973) 897 5091</p>
                        <p className="text-sm mb-0 text-muted"><i className="fas fa-envelope mr-2 text-muted " /> john@architeck.io</p>
                        <p className="text-sm mb-0 text-mute">LINE: (313) 564 5300 - Bankruptcy</p>
                        </div>
                    </Col>
               </Row>
             
            </div>
           
            <div className="archk-on-call-wrapper text-centr border-bottom">
                
               <Row>

                    <Col>
                        <div className=''>
                            <h4 className="mb-0 text-dark">Next Event</h4>
                            <p className="text-sm mb--2 text-muted">341 Meeting Of Credits</p>
                            <p className="text-sm mb-0 text-muted">10/18/2022 8:30 AM</p>
                        </div>
                    </Col>

                    <Col>
                        <div className=''>
                            <h4 className="mb-0 text-dark">Cases</h4>
                            <p className="text-sm mb--2 text-muted">Architeck - Bankruptcy</p>
                            <p className="text-sm mb--2 text-muted">Architeck - Bankruptcy</p>
                        </div>
                    </Col>
               </Row>
             
            </div>
           
            <div className="archk-on-call-wrapper text-centr border-bottom">
                <h4 className="mb-0 text-dark">Last Note</h4>
                <p className="text-sm mb--2 text-muted">Called client for collection, left voicemail said would call back. Awaiting response.</p>
                <p className="text-sm mb-0 text-mute text- text-dark"><small>- Lina M. 10/11/2022 8:34 PM</small></p>
            </div>

            {icons}

        </div>
    )

}


const mapStateToProps = state => {
    return {
        on_call: state.state.on_call,
    };
};

export default connect(mapStateToProps, '')(OnCall);