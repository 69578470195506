/*
Documentation

compile and export all reducers for this system

*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import system from './reducers/system/system';
import auth from './reducers/auth/auth';
import device from './reducers/device/device';
import company from './reducers/company';
import load from './reducers/load/load';
import config from './reducers/config/config';
import tutorial from './reducers/tutorial';
import users from './reducers/users/users';
import appointment_templates from './reducers/appointment_templates/appointment_templates';
import contact_tags from './reducers/contact_tags/contact_tags';
import automations from './reducers/automations/automations';
import matter_documents from './reducers/matter_documents/matter_documents';
import signing_templates from './reducers/signing_templates/signing_templates';
import email_templates from './reducers/email_templates/email_templates';
import text_templates from './reducers/text_templates/text_templates';
import forms from './reducers/forms/forms';
import objects from './reducers/objects';
import divisions from './reducers/divisions';
import state from './reducers/state';
import socket from './reducers/socket';
import matters from './reducers/matters';
import document_center from './reducers/document_center';
import call_center from './reducers/call_center';
import contacts from './reducers/contacts';

const rootReducer = combineReducers({
    auth,
    system,
    device,
    company,
    load,
    tutorial,
    config,
    users,
    appointment_templates,
    contact_tags,
    automations,
    matter_documents,
    signing_templates,
    email_templates,
    text_templates,
    forms,
    objects,
    divisions,
    state,
    socket,
    matters,
    document_center,
    call_center,
    contacts
})

const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store
