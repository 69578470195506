/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    contact_tags: [
       { _id: 123, name: 'test tag 1' },
       { _id: 456, name: 'test tag 2' },
    ],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_CONTACT_TAGS:

            return {
                ...state,
                company: action.payload,
            }

        default: return state;

    }

}

export default reducer;
